import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import api from '@/api/login';
import './style/enterSuccess.scss';
import logoIcon from '@/assets/img/logo@2x.png';
import icon from '../../../assets/img/home/cate1.png';
import { useSelector } from 'react-redux';
const EnterSuccess = (props: any) => {
    const { profileInfo } = useSelector((state: any) => state.state);
    const navigate = useNavigate();
    const [lotteryInfo, setLotteryInfo] = useState({
        drawDate: '-',
        participants: 0,
        pointsOwn: 0,
    });
    useEffect(() => {
        if (profileInfo.isExpired) {
            navigate('/introductionPage?type=2');
            return;
        }
        const info = JSON.parse(window.sessionStorage.getItem('lotteryInfo') ?? '{}');
        if (info.drawDate) {
            setLotteryInfo({ ...info });
        }
    }, []);
    return (
        <div className="enterSuccess-main r-center">
            <img src={logoIcon} className="logo-box" alt="" />
            <div className="profile-rightBox">
                <div className="r-left profile-numBox">
                    <div className="statistics-card gift-card">
                        Your current available reward balance
                        <div className="sta-num gift-num">{lotteryInfo.pointsOwn ?? '0'}</div>
                    </div>
                </div>
                <div className="enter-box">
                    <div className="r-center">
                        <i className="succ-icon"></i>
                    </div>
                    <div className="succ-tit">Competition entry</div>
                    <div className="succ-tip">Entry successful!</div>
                    <div className="prize-statusBox">
                        {lotteryInfo.participants ? (
                            <div className="winuser-box">
                                You and {lotteryInfo.participants} people have entered to win
                            </div>
                        ) : (
                            <div className="winuser-box">You</div>
                        )}
                        <p className="succ-tip">have entered to win</p>
                    </div>
                </div>
                <div className="question-opeationBox">
                    <p className="succ-tip">
                        Winner will be drawn on {lotteryInfo.drawDate ? `${lotteryInfo.drawDate}th` : '-'},<br></br> We
                        will email you the results.
                    </p>
                    <Button className="r-primary_btn w220" onClick={() => navigate('/prize')}>
                        Enter Again
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default EnterSuccess;
