import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import api from '@/api/login';
import './index.scss';
import logoIcon from '@/assets/img/logo@2x.png';
import { useSelector } from 'react-redux';
import MembershipOptionCard from '@/components/membership-option-card';
import PrizesAvailableMember from '@/components/prizes-available-member';
import memberData from '@/utils/member';
import { defaultInfo } from '@/typings/allType';
const Membership = (props: any) => {
    const { deviceType, profileInfo, isFree } = useSelector((state: any) => state.state);
    const navigate = useNavigate();
    const [memberKey, setMemberKey] = useState(1);
    const [packageInfo, setPackageInfo] = useState<defaultInfo>({});
    const subscriptionList = memberData.subscriptionList;
    useEffect(() => {
        const index = subscriptionList.findIndex(v => +v.subscriptionId === +profileInfo.subscriptionId);
        setMemberKey(index !== -1 ? index : 0);
        const info = JSON.parse(window.sessionStorage.getItem('profilePackageInfo') ?? '{}');
        if (info.subscriptionPackageId) {
            // 套餐信息
            setPackageInfo({ ...info });
        }
    }, []);
    return (
        <div className="membership-main">
            <div className="r-center">
                <img src={logoIcon} className="logo-box" alt="" />
            </div>
            <h2 className="mar24 mb-h2">My membership</h2>
            <div className="pad24">
                <div className="sub-card">
                    {Number(profileInfo.subscriptionId) === 0 ? (
                        <div className="subCard_top r-left" onClick={() => navigate('/membership')}>
                            <div>
                                <div className="sub-tit">Freemium</div>
                                <div className="sub-date">28 Days</div>
                                <div className="sub-expird">
                                    Exp. Date: {profileInfo.subscriptionExpiryTimeShow ?? '-'}
                                </div>
                            </div>
                            <div className="price-box r-left">
                                <span className="priceLef">$ </span>
                                <span className="priceNum">0.00</span>
                            </div>
                        </div>
                    ) : (
                        <div className="subCard_top r-left">
                            <img src={subscriptionList[memberKey].icon} className="sub-icon" alt="" />
                            <div>
                                <div className="sub-tit">{subscriptionList[memberKey].tit}</div>
                                <div className="sub-date">{packageInfo.date ?? ''}</div>
                                <div className="sub-expird">
                                    Exp. Date: {profileInfo.subscriptionExpiryTimeShow ?? '-'}
                                </div>
                            </div>
                            <div className="price-box r-left">
                                <span className="priceLef">$ </span>
                                <span className="priceNum">{packageInfo.price ?? '0.00'}</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <h2>Prizes available for members</h2>
            <div className="menbership-box">
                <PrizesAvailableMember isShowFree={false}></PrizesAvailableMember>
            </div>
            <h2>Membership Option</h2>
            <div className="menbership-box">
                <MembershipOptionCard type={2} isShowFree={false} className="noBtm-mar"></MembershipOptionCard>
            </div>
        </div>
    );
};

export default Membership;
