import React, { useEffect } from 'react';
import { Spin } from 'antd';
import { Route, Routes, useLocation } from 'react-router-dom';
import './index.scss';
import { useSelector } from 'react-redux';
import KeepAlive from 'react-activation';
import usePrevious from '@/hook/usePrevious';
import TopNav from '@/components/top-nav/index';
const LayoutMain = props => {
    const commonplace = useSelector((state: any) => state.commonplace);
    const location = useLocation();
    const prevLocation: any = usePrevious(location);
    return (
        <>
            <Spin spinning={commonplace.loading} tip={commonplace.text} wrapperClassName={commonplace.class}>
                <div className="layout-container">
                    <div className="header_nav">
                        <TopNav type={3}></TopNav>
                    </div>
                    <Routes>
                        {props.routes.map((route: any, index: any) => {
                            return route.KeepAlive ? (
                                <Route
                                    path={route.path}
                                    key={index}
                                    element={
                                        <KeepAlive cacheKey={route.path} saveScrollPosition="screen" name={route.name}>
                                            <route.component
                                                prevLocation={prevLocation}
                                                routes={route.childrens ? route.childrens : []}
                                            />
                                        </KeepAlive>
                                    }
                                ></Route>
                            ) : (
                                <Route
                                    path={route.path}
                                    key={index}
                                    element={
                                        <route.component
                                            prevLocation={prevLocation}
                                            routes={route.childrens ? route.childrens : []}
                                        />
                                    }
                                ></Route>
                            );
                        })}
                    </Routes>
                </div>
            </Spin>
        </>
    );
};

export default LayoutMain;
