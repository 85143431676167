import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import api from '@/api/profile';
import './index.scss';
import logoIcon from '@/assets/img/logo@2x.png';
import { useSelector } from 'react-redux';
import memberData from '@/utils/member';
import { setProfileInfo } from '@/store/stateSlice';
import { useAppDispatch } from '@/store/hooks';
import { defaultInfo } from '@/typings/allType';
import useLoading from '@/hook/useLoading';
const Profile = (props: any) => {
    const { deviceType, profileInfo } = useSelector((state: any) => state.state);
    const { mainLoading, setLoadingStatus } = useLoading();
    const navigate = useNavigate();
    const subscriptionList = memberData.subscriptionList;
    const memberOptionList = memberData.memberOptionList;
    const [memberKey, setMemberKey] = useState(0);
    const [isFree, setIsFree] = useState(false);
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({
        age: '',
        displayName: '',
        email: '',
        gender: '',
        point: 0,
        postcode: '',
        subscriptionAmount: 0,
        subscriptionDuration: 0,
        subscriptionExpiryTime: '',
        subscriptionId: '',
        subscriptionName: '',
        subscriptionPackageId: '',
        userId: '',
        subscriptionExpiryTimeShow: '',
    });
    const [packageInfo, setPackageInfo] = useState<defaultInfo>({});
    const dispatch = useAppDispatch();
    const getInfo = () => {
        try {
            api.getUserProfileInfo(res => {
                const { data, code, message } = res;
                setLoadingStatus(false);
                if (code === 200) {
                    if (data && data !== null) {
                        setIsFree(data.subscriptionId === '0');
                        if (data.subscriptionId != null && data.subscriptionId !== '0') {
                            const index = Number(data.subscriptionId) - 1;
                            setMemberKey(index);
                            const packageInfo = memberOptionList[index].priceList.find(
                                v => v.subscriptionPackageId === +data.subscriptionPackageId
                            );
                            setPackageInfo({ ...packageInfo });
                        }
                        data.point = window.utils.formatPonit(data.point ?? 0);
                        data.isExpired = window.utils.isExpired(+data.subscriptionExpiryTime);
                        data.subscriptionExpiryTimeShow =
                            data.subscriptionExpiryTime !== null
                                ? window.utils.foramtTimeDMYTime(+data.subscriptionExpiryTime)
                                : '-';
                        setState({ ...state, ...data });
                        dispatch(setProfileInfo({ ...data }));
                        window.sessionStorage.setItem('profileInfo', JSON.stringify({ ...state, ...data }));
                    }
                } else {
                    window.format.alert('error', message, code);
                }
            });
        } catch (error) {
            setLoadingStatus(false);
        }
    };
    useEffect(() => {
        setLoadingStatus(true);
        getInfo();
    }, []);
    return (
        <>
            {!mainLoading ? (
                <div className="Profile-main r-center">
                    <img src={logoIcon} className="logo-box" alt="" />
                    <div className="profile-rightBox">
                        <div className="r-left profile-numBox">
                            <div className="statistics-card">
                                <div className="sta-num">{state.displayName ? state.displayName : '-'}</div>
                                <div className="mar10">Reward points</div>
                                <div className="sta-num">{state.point ?? '0'}</div>
                            </div>
                        </div>
                        <div className="mem-tit">Membership</div>
                        <div className="sub-card" style={{ cursor: 'pointer' }}>
                            {isFree ? (
                                <div
                                    className="subCard_top r-left"
                                    onClick={() => {
                                        navigate('/membership');
                                    }}
                                >
                                    <div>
                                        <div className="sub-tit">Freemium</div>
                                        <div className="sub-date">28 Days</div>
                                        <div className="sub-expird">
                                            Exp. Date: {state.subscriptionExpiryTimeShow ?? '-'}
                                        </div>
                                    </div>
                                    <div className="price-box r-left">
                                        <span className="priceLef">$ </span>
                                        <span className="priceNum">0.00</span>
                                    </div>
                                </div>
                            ) : (
                                <div
                                    className="subCard_top r-left"
                                    onClick={() => {
                                        window.sessionStorage.setItem(
                                            'profilePackageInfo',
                                            JSON.stringify(packageInfo)
                                        );
                                        navigate('/membership');
                                    }}
                                >
                                    <img src={subscriptionList[memberKey].icon} className="sub-icon" alt="" />
                                    <div>
                                        <div className="sub-tit">{subscriptionList[memberKey].tit}</div>
                                        <div className="sub-date">{packageInfo.date ?? ''}</div>
                                        <div className="sub-expird">
                                            Exp. Date: {state.subscriptionExpiryTimeShow ?? '-'}
                                        </div>
                                    </div>
                                    <div className="price-box r-left">
                                        <span className="priceLef">$ </span>
                                        <span className="priceNum">{packageInfo.price ?? '0.00'}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="profileItem_list">
                            <div className="r-left opeation-item" onClick={() => navigate('/edit-profile')}>
                                Edit profile
                                <i className="marLeft chevron-right_icon"></i>
                            </div>
                            <div className="r-left opeation-item" onClick={() => navigate('/pointsRecord')}>
                                Points record
                                <i className="marLeft chevron-right_icon"></i>
                            </div>
                            <div className="r-left opeation-item" onClick={() => navigate('/drawRecord')}>
                                Draw record
                                <i className="marLeft chevron-right_icon"></i>
                            </div>
                            <div className="r-left opeation-item" onClick={() => navigate('/prizeRecord')}>
                                Prize record
                                <i className="marLeft chevron-right_icon"></i>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                ''
            )}
        </>
    );
};

export default Profile;
