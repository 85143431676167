import React, { Suspense, useState, useEffect } from 'react';
import { HashRouter, Routes, Route } from 'react-router-dom';
import { AxiosInterceptor } from './utils/index';
import auto from '@/utils/autofont';
import '@/assets/fonts/font.scss';
import routes from './router';
import { useDispatch, useSelector } from 'react-redux';
import api from '@/api/login';
import { setDeviceType } from '@/store/stateSlice';
import { message } from 'antd';
import { hooks } from 'react-vant';
const App = () => {
    const dispatch = useDispatch();
    const [device, setDevice] = useState('pc');
    const [showPage, setShowPage] = useState(false);
    const getDevice = () => {
        const clientWidth = document.documentElement.clientWidth;
        // console.log(`clientWidth: ${clientWidth}`);
        setDevice(clientWidth < 576 ? 'mb' : 'pc');
        dispatch(setDeviceType(clientWidth < 576 ? 'mb' : 'pc'));
        setTimeout(() => {
            setShowPage(true);
        }, 200);
    };
    const { width, height } = hooks.useWindowSize();
    useEffect(() => {
        const doc = document.documentElement;
        doc.style.setProperty('--app-height', `${height + 1}px`);
    }, [width, height]);
    const getPublicKey = () => {
        api.getSecret(res => {
            const { code, data } = res;
            if (code === 200) {
                window.sessionStorage.setItem('publicKey', data);
            } else {
                message.error(res.message);
            }
        });
    };
    window.onresize = function () {
        auto.autoFont();
        getDevice();
    };
    useEffect(() => {
        auto.autoFont();
        getDevice();
        getPublicKey();
    }, []);
    return (
        <HashRouter>
            <AxiosInterceptor>
                <Suspense>
                    <Routes>
                        {routes.map((route, index) => {
                            return (
                                <Route
                                    path={route.path}
                                    key={index}
                                    element={
                                        <div className={`${device}-main ${showPage ? 'show-page' : 'hidden-page'}`}>
                                            <route.component routes={route.childrens ? route.childrens : []} />
                                        </div>
                                    }
                                ></Route>
                            );
                        })}
                    </Routes>
                </Suspense>
            </AxiosInterceptor>
        </HashRouter>
    );
};

export default App;
