import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Input, message, Modal } from 'antd';
import api from '@/api/login';
import './index.scss';
import logoIcon from '@/assets/img/logo@2x.png';
import { useAppDispatch } from '@/store/hooks';
import TopNav from '@/components/top-nav/index';
import { useSelector } from 'react-redux';
import { setUserInfo } from '@/store/stateSlice';
const SendSmsCode = (props: any) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isBlur, setIsBlur] = useState(false);
    const [state, setState] = useState({
        phone: '',
    });
    const { userInfo } = useSelector((state: any) => state.state);
    const dispatch = useAppDispatch();
    const nextStep = () => {
        if (!state.phone) {
            message.error('Please enter your mobile phone number');
            return;
        }
        if (!new RegExp(/^(0\d{9}|[1-9]\d{8})$/).test(state.phone.toString().split(' ').join(''))) {
            message.error('Please enter a valid mobile phone number');
            return;
        }
        setLoading(true);
        const pat = {
            areaCode: '+61',
            type: 1,
            userMobile: state.phone.split(' ').join(''),
        };
        api.sendSmsCode(pat, res => {
            const { data, code, message } = res;
            setLoading(false);
            if (code === 200) {
                const obj = Object.assign({ ...userInfo }, { ...state });
                dispatch(setUserInfo(obj));
                navigate('/verifyCode');
            } else {
                window.format.alert('error', message, code);
            }
        });
    };
    return (
        <div className="login-main">
            <TopNav type={1}></TopNav>
            <div className="r-left login-box">
                <img src={logoIcon} className="logo-box" alt="" />
                <div className="login-form">
                    <h1>Forgot password</h1>
                    <div>
                        <p className="form-tip">Phone Number</p>
                        <div className="phone-inputBox">
                            <div className="left-iconBox r-left">
                                <div className="r-center auBox">
                                    <i className="au-icon"></i>
                                </div>
                                <span className="phone-codebox">(+61)</span>
                            </div>
                            <Input
                                className="r-input phone-box"
                                type="tel"
                                value={state.phone}
                                maxLength={isBlur ? 12 : window.utils.isStartsWith04(state.phone) ? 10 : 9}
                                onBlur={(e: any) => {
                                    setIsBlur(true);
                                    setState({ ...state, phone: window.utils.formatNumber(state.phone) });
                                }}
                                onFocus={(e: any) => {
                                    setIsBlur(false);
                                    e.target.value = e.target.value.replace(/\D/g, '');
                                    setState({ ...state, phone: e.target.value.split(' ').join('') });
                                }}
                                onInput={(e: any) => {
                                    e.target.value = e.target.value.replace(/\D/g, '');
                                    setState({ ...state, phone: e.target.value });
                                }}
                                placeholder="40 000 000"
                            />
                        </div>
                    </div>
                    <Button
                        className="r-primary_btn mart40"
                        loading={loading}
                        disabled={!state.phone}
                        onClick={() => nextStep()}
                    >
                        Continue
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default SendSmsCode;
