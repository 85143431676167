import { useEffect, useState } from 'react';
import './index.scss';
const NumberInput = props => {
    const [prizeNum, setPrizeNum] = useState(0);
    const opeationNum = (type: number) => {
        if (props.isDisabled) return;
        let num = prizeNum;
        if (type === 1 && prizeNum) {
            num -= 1;
            setPrizeNum(prizeNum - 1);
        }
        if (type === 2 && prizeNum < props.maxPrzie) {
            num += 1;
            setPrizeNum(prizeNum + 1);
        }
        if (type === 2 && prizeNum === props.maxPrzie) {
            window.utils.alert('error', 'Your current available points balance is insufficient');
        }
        const prizes = JSON.parse(JSON.stringify(props.val));
        const obj = prizes[props.index];
        obj.prizeNum = num;
        prizes.splice(props.index, 1, obj);
        props.setPrizeList([...prizes]);
    };
    useEffect(() => {
        setPrizeNum(props.prizeNum);
    }, [props.prizeNum]);
    return (
        <div className={`number-input_box ${props.className}`}>
            <div
                className={`numberIcon-box r-center ${props.isDisabled || !prizeNum ? 'disabled' : ''}`}
                onClick={() => opeationNum(1)}
            >
                <i className={`jan-icon`}></i>
            </div>
            <div className={`number-box ${props.isDisabled ? 'disabled' : ''}`}>{prizeNum}</div>
            <div
                className={`numberIcon-box r-center ${
                    props.isDisabled || prizeNum >= props.maxPrzie ? 'disabled' : ''
                }`}
                onClick={() => opeationNum(2)}
            >
                <i className={`jia-icon`}></i>
            </div>
        </div>
    );
};
export default NumberInput;
