import axios from '../utils/index';
import { Pat, HttpResponse } from '@/typings/request';
const loginApi = {
    getSecret(callback: (T: any) => void) {
        axios
            .get(`/v1/public/rsa/get-publicKey`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    login(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/portal/user/login`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    logOut(callback: (T: any) => void) {
        axios
            .post(`/portal/user/logout`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    updatePassword(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/portal/user/findPassword`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    sendSmsCode(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/portal/user/sendSmsCode`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    checkSmsCode(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/portal/user/checkSmsCode`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    createUser(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/portal/user/create`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
};

export default loginApi;
