import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Input, message, Modal } from 'antd';
import api from '@/api/login';
import './index.scss';
import logoIcon from '@/assets/img/logo@2x.png';
import { useAppDispatch } from '@/store/hooks';
import { setUserInfo } from '@/store/stateSlice';
import TopNav from '@/components/top-nav/index';
import { useSelector } from 'react-redux';
const SignUp = (props: any) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isBlur, setIsBlur] = useState(false);
    const [state, setState] = useState({
        fullName: '',
        email: '',
        phone: '',
    });
    const dispatch = useAppDispatch();
    const { userInfo } = useSelector((state: any) => state.state);
    const nextStep = () => {
        if (!state.fullName) {
            message.error('Please enter your full name');
            return;
        }
        if (!state.email) {
            message.error('Please enter your email address');
            return;
        }
        if (!window.utils.isEmail(state.email)) {
            message.error('Invalid email address entered');
            return;
        }
        if (!state.phone) {
            message.error('Please enter your mobile phone number');
            return;
        }
        if (!new RegExp(/^(0\d{9}|[1-9]\d{8})$/).test(state.phone.toString().split(' ').join(''))) {
            message.error('Please enter a valid mobile phone number');
            return;
        }
        setLoading(true);
        const pat = {
            areaCode: '+61',
            type: 0,
            userMobile: state.phone.split(' ').join(''),
        };
        api.sendSmsCode(pat, res => {
            const { data, code, message } = res;
            setLoading(false);
            if (code === 200) {
                const obj = Object.assign({ ...userInfo }, { ...state });
                dispatch(setUserInfo(obj));
                navigate(`/verifyCode?type=2`);
            } else {
                window.format.alert('error', message, code);
            }
        });
    };
    return (
        <div className="login-main">
            <TopNav type={2}></TopNav>
            <div className="r-left login-box">
                <img src={logoIcon} className="logo-box" alt="" />
                <div className="login-form">
                    <h1>Sign up now!</h1>
                    <div className="baseSize">
                        <p className="form-tip">Full Name</p>
                        <Input
                            className="r-input"
                            maxLength={50}
                            value={state.fullName}
                            onInput={(e: any) => setState({ ...state, fullName: e.target.value })}
                            placeholder="Enter your full name"
                        />
                    </div>
                    <div className="baseSize">
                        <p className="form-tip">Email Address</p>
                        <Input
                            className="r-input"
                            maxLength={100}
                            value={state.email}
                            onInput={(e: any) => setState({ ...state, email: e.target.value })}
                            placeholder="Enter your email address"
                        />
                    </div>
                    <div>
                        <p className="form-tip">Phone Number</p>
                        <div className="phone-inputBox">
                            <div className="left-iconBox r-left">
                                <div className="r-center auBox">
                                    <i className="au-icon"></i>
                                </div>
                                <span className="phone-codebox">(+61)</span>
                            </div>
                            <Input
                                className="r-input phone-box"
                                type="tel"
                                value={state.phone}
                                maxLength={isBlur ? 12 : window.utils.isStartsWith04(state.phone) ? 10 : 9}
                                onBlur={(e: any) => {
                                    setIsBlur(true);
                                    setState({ ...state, phone: window.utils.formatNumber(state.phone) });
                                }}
                                onFocus={(e: any) => {
                                    setIsBlur(false);
                                    e.target.value = e.target.value.replace(/\D/g, '');
                                    setState({ ...state, phone: e.target.value.split(' ').join('') });
                                }}
                                onInput={(e: any) => {
                                    e.target.value = e.target.value.replace(/\D/g, '');
                                    setState({ ...state, phone: e.target.value });
                                }}
                                placeholder="Enter your phone number"
                            />
                        </div>
                    </div>
                    <Button
                        className="r-primary_btn mart40 mart24"
                        disabled={!state.email && !state.phone && !state.fullName}
                        onClick={() => nextStep()}
                        loading={loading}
                    >
                        Continue
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default SignUp;
