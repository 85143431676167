import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, DatePicker, Input, Select } from 'antd';
import api from '@/api/profile';
import './index.scss';
import logoIcon from '@/assets/img/logo@2x.png';
import { useSelector } from 'react-redux';
import { defaultInfo } from '@/typings/allType';
import dayjs from 'dayjs';
import { RangePickerProps } from 'antd/es/date-picker';
const EditProfile = (props: any) => {
    const { deviceType } = useSelector((state: any) => state.state);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState<defaultInfo>({});
    const [state, setState] = useState({
        fullName: '',
        email: '',
        phone: '',
        gender: '',
        age: '',
        postcode: '',
        ageNum: '',
    });
    const handleChange = val => {
        setState({ ...state, gender: val });
    };
    const changeAge = val => {
        const age = val ? window.utils.foramtAgeTime(val) : '';
        const ageNum: any = val ? dayjs(val).unix() * 1000 : '';
        setState({ ...state, age, ageNum });
    };
    const save = () => {
        if (!state.fullName) {
            window.utils.alert('error', 'Please enter your full name');
            return;
        }
        if (!state.email) {
            window.utils.alert('error', 'Please enter your email address');
            return;
        }
        if (!window.utils.isEmail(state.email)) {
            window.utils.alert('error', 'Invalid email address entered');
            return;
        }
        if (!state.phone) {
            window.utils.alert('Please enter your mobile phone number');
            return;
        }
        if (!state.gender) {
            window.utils.alert('error', 'Please select your gender');
            return;
        }
        if (!state.age) {
            window.utils.alert('error', 'Please select your month/year of birth');
            return;
        }
        if (!state.postcode) {
            window.utils.alert('error', 'Please enter your postcode');
            return;
        }
        setLoading(true);
        const pat = {
            age: state.ageNum,
            displayName: state.fullName,
            email: state.email,
            gender: state.gender,
            postcode: state.postcode,
            userId: info.userId,
        };
        api.editUser(pat, res => {
            const { data, code, message } = res;
            setLoading(false);
            if (code === 200) {
                navigate('/profile');
            } else {
                window.format.alert('error', message, code);
            }
        });
    };
    useEffect(() => {
        const info = JSON.parse(window.sessionStorage.getItem('profileInfo') ?? "{userId:''}");
        if (info.userId) {
            setInfo(info);
            setState({
                fullName: info.displayName,
                email: info.email,
                phone: info.mobileNumber ? window.utils.formatNumber(info.mobileNumber) : '',
                gender: info.gender.toString(),
                age: info.age ? window.utils.foramtAgeTime(+info.age) : '',
                postcode: info.postcode,
                ageNum: info.age,
            });
        }
    }, []);
    const disabledDate: RangePickerProps['disabledDate'] = current => {
        return current && current > dayjs().endOf('day');
    };
    return (
        <div className="editProfile-main r-center">
            <img src={logoIcon} className="logo-box" alt="" />
            <div className="profile-form">
                <h1>Edit profile</h1>
                <div className="baseSize">
                    <p className="form-tip">Full Name</p>
                    <Input
                        className="r-input"
                        maxLength={50}
                        value={state.fullName}
                        onInput={(e: any) => setState({ ...state, fullName: e.target.value })}
                        placeholder="Enter your full name"
                    />
                </div>
                <div className="baseSize">
                    <p className="form-tip">Email Address</p>
                    <Input
                        className="r-input"
                        maxLength={100}
                        value={state.email}
                        onInput={(e: any) => setState({ ...state, email: e.target.value })}
                        placeholder="Enter your email address"
                    />
                </div>
                <div className="baseSize">
                    <p className="form-tip">Phone Number</p>
                    <Input className="r-input disabledInput" value={state.phone} disabled placeholder="400 000 000" />
                </div>
                <div className="baseSize">
                    <p className="form-tip">Gender</p>
                    <Select
                        className="r-select"
                        style={{ width: '100%' }}
                        onChange={handleChange}
                        value={state.gender}
                        placeholder="Select"
                        suffixIcon={<i className="sel_arrow_icon"></i>}
                        options={[
                            { value: '1', label: 'Female' },
                            { value: '2', label: 'Male' },
                        ]}
                    />
                </div>
                <div className="baseSize">
                    <p className="form-tip">Age</p>
                    <div className="ageSel-input">
                        <Input
                            className="r-input"
                            readOnly
                            value={state.age}
                            placeholder="Month/Year of Birth"
                            suffix={<i className="sel_arrow_icon"></i>}
                            // suffix={
                            //     state.age ? (
                            //         <i
                            //             className="close_icon"
                            //             style={{ zIndex: 100 }}
                            //             onClick={() => setState({ ...state, age: '', ageNum: '' })}
                            //         ></i>
                            //     ) : (
                            //         <i className="sel_arrow_icon"></i>
                            //     )
                            // }
                        />
                        <DatePicker
                            className="r-age-date"
                            disabledDate={disabledDate}
                            inputReadOnly
                            onChange={changeAge}
                            value={dayjs(new Date(+state.ageNum))}
                            picker="month"
                        />
                    </div>
                </div>
                <div className="baseSize">
                    <p className="form-tip">Postcode</p>
                    <Input
                        className="r-input"
                        maxLength={4}
                        value={state.postcode}
                        onInput={(e: any) => {
                            e.target.value = e.target.value.replace(/\D/g, '');
                            setState({ ...state, postcode: e.target.value });
                        }}
                        placeholder="Enter your postcode"
                    />
                </div>
                <div className="r-left profileform-footer">
                    <Button className="r-defaultBlick_btn cancel-btn" onClick={() => navigate('/profile')}>
                        Cancel
                    </Button>
                    <Button
                        className="r-primary_btn"
                        disabled={!state.fullName && !state.email && !state.age && !state.gender && !state.postcode}
                        onClick={() => save()}
                        loading={loading}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default EditProfile;
