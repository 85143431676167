import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Input, message, Modal } from 'antd';
import api from '@/api/login';
import './index.scss';
import logoIcon from '@/assets/img/logo@2x.png';
import { useAppDispatch } from '@/store/hooks';
import TopNav from '@/components/top-nav/index';
import JSEncrypt from 'jsencrypt';
import { useSelector } from 'react-redux';
import { setFreeDay, setIsFree, setProfileInfo, setUserInfo } from '@/store/stateSlice';
import apiProfile from '@/api/profile';
const SetPassword = (props: any) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const type = searchParams.get('type') ?? '1';
    const [psType, setPsType] = useState('password');
    const [psNewType, setPsNewType] = useState('password');
    const navigate = useNavigate();
    const { userInfo } = useSelector((state: any) => state.state);
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({
        password: '',
        rePassword: '',
    });
    const encrypt = new JSEncrypt();
    const dispatch = useAppDispatch();
    const getInfo = () => {
        apiProfile.getUserProfileInfo(res => {
            const { data, code, message } = res;
            if (code === 200) {
                if (data && data !== null) {
                    data.point = window.utils.formatPonit(data.point ?? 0);
                    data.isExpired = window.utils.isExpired(+data.subscriptionExpiryTime);
                    data.subscriptionExpiryTimeShow =
                        data.subscriptionExpiryTimeShow !== null
                            ? window.utils.foramtTimeDMYTime(data.subscriptionExpiryTimeShow)
                            : '-';

                    dispatch(setProfileInfo({ ...data }));
                    dispatch(setIsFree(data.subscriptionId === '0'));
                }
            } else {
                window.format.alert('error', message, code);
            }
        });
    };
    const toHome = () => {
        if (!state.password || !state.rePassword) {
            window.utils.alert('error', 'Please enter a password of 8-16 digits');
            return;
        }
        if (state.password.length < 8) {
            window.utils.alert('error', 'Please enter a password of 8-16 digits');
            return;
        }
        if (state.password !== state.rePassword) {
            window.utils.alert('error', 'The 2 passwords entered are not the same');
            setState({ password: '', rePassword: '' });
            return;
        }
        setLoading(true);
        api.getSecret(res => {
            const { code, data } = res;
            if (code === 200) {
                encrypt.setPublicKey(data);
                window.sessionStorage.setItem('publicKey', data);
                if (Number(type) === 2) {
                    // 注册
                    const pat = {
                        email: userInfo.email,
                        age: Date.parse(new Date(userInfo.age) as any),
                        areaCode: '+61',
                        displayName: userInfo.fullName,
                        gender: userInfo.gender,
                        mobileNumber: userInfo.phone.split(' ').join(''),
                        postcode: userInfo.postcode,
                        password: encrypt.encrypt(state.password) || '',
                    };
                    api.createUser(pat, res => {
                        const { data, code, message } = res;
                        setLoading(false);
                        if (code === 200) {
                            window.sessionStorage.setItem('admin-satoken', data.token);
                            const obj = Object.assign({ ...userInfo }, { ...data });
                            dispatch(setUserInfo(obj));
                            dispatch(setFreeDay(data.subscriptionTrialDays ? +data.subscriptionTrialDays : 0));
                            getInfo();
                            navigate('/introductionPage');
                        } else {
                            window.format.alert('error', message, code);
                        }
                    });
                } else {
                    // 找回密码
                    const pat = {
                        mobileNumber: userInfo.phone.split(' ').join(''),
                        code: userInfo.code,
                        password: encrypt.encrypt(state.password) || '',
                    };
                    api.updatePassword(pat, res => {
                        const { data, code, message } = res;
                        setLoading(false);
                        if (code === 200) {
                            navigate('/login');
                        } else {
                            window.format.alert('error', message, code);
                        }
                    });
                }
            } else {
                message.error(res.message);
            }
        });
    };
    return (
        <div className="login-main">
            <TopNav type={Number(type)}></TopNav>
            <div className="r-left login-box">
                <img src={logoIcon} className="logo-box" alt="" />
                <div className="login-form">
                    <h1>{Number(type) === 1 ? 'Set new password' : 'Set a password'}</h1>
                    <div className="baseSize">
                        <p className="form-tip">{Number(type) === 1 ? 'New password' : 'Password'}</p>
                        <Input
                            className="r-input"
                            placeholder={Number(type) === 1 ? 'Enter new password' : 'Enter your password'}
                            type={psType}
                            maxLength={16}
                            value={state.password}
                            onInput={(e: any) => {
                                setState({ ...state, password: e.target.value });
                            }}
                            suffix={
                                psType === 'text' ? (
                                    <i className="eye-icon" onClick={() => setPsType('password')}></i>
                                ) : (
                                    <i className="eye-slash-icon" onClick={() => setPsType('text')}></i>
                                )
                            }
                        />
                        <p className="form-tip">{Number(type) === 1 ? 'Re-enter Password' : 'Confirm Password'}</p>
                        <Input
                            className="r-input"
                            placeholder={Number(type) === 1 ? 'Re-enter new password' : 'Confirm your password'}
                            type={psNewType}
                            maxLength={16}
                            value={state.rePassword}
                            onInput={(e: any) => {
                                setState({ ...state, rePassword: e.target.value });
                            }}
                            suffix={
                                psNewType === 'text' ? (
                                    <i className="eye-icon" onClick={() => setPsNewType('password')}></i>
                                ) : (
                                    <i className="eye-slash-icon" onClick={() => setPsNewType('text')}></i>
                                )
                            }
                        />
                    </div>
                    <Button
                        className="r-primary_btn mart40 mart116"
                        disabled={!state.password && !state.rePassword}
                        loading={loading}
                        onClick={() => toHome()}
                    >
                        {Number(type) === 1 ? 'Confirm' : 'Continue'}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default SetPassword;
