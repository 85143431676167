import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Input, message, Modal } from 'antd';
import api from '@/api/login';
import './index.scss';
import logoIcon from '@/assets/img/logo@2x.png';
import { useAppDispatch } from '@/store/hooks';
import { setEmail, setPassword } from '@/store/userinfoSlice';
import { setIsFree, setProfileInfo, setFreeDay } from '@/store/stateSlice';
import TopNav from '@/components/top-nav/index';
import JSEncrypt from 'jsencrypt';
import { defaultInfo } from '@/typings/allType';
import apiProfile from '@/api/profile';
const Login = (props: any) => {
    const encrypt = new JSEncrypt();
    const [publicKey, setPublicKey] = useState('');
    const [psType, setPsType] = useState('password');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isBlur, setIsBlur] = useState(false);
    const [state, setState] = useState({
        email: '',
        password: '',
    });
    const dispatch = useAppDispatch();
    const enter = e => {
        if (e.keyCode === 13) {
            toHome();
        }
    };
    const getInfo = (isExpired: boolean) => {
        apiProfile.getUserProfileInfo(res => {
            const { data, code, message } = res;
            if (code === 200) {
                if (data && data !== null) {
                    data.point = window.utils.formatPonit(data.point ?? 0);
                    data.isExpired = window.utils.isExpired(+data.subscriptionExpiryTime);
                    data.subscriptionExpiryTimeShow =
                        data.subscriptionExpiryTimeShow !== null
                            ? window.utils.foramtTimeDMYTime(data.subscriptionExpiryTimeShow)
                            : '-';

                    dispatch(setProfileInfo({ ...data }));
                }
                if (isExpired) {
                    navigate('/introductionPage?type=2');
                    return;
                }
                navigate('/home');
            } else {
                window.format.alert('error', message, code);
            }
        });
    };
    const toHome = () => {
        if (!state.email) {
            message.error('Please enter your mobile phone number');
            return;
        }
        if (!new RegExp(/^(0\d{9}|[1-9]\d{8})$/).test(state.email.toString().split(' ').join(''))) {
            message.error('Please enter a valid mobile phone number');
            return;
        }
        if (!state.password || state.password.length < 8) {
            message.error('Please enter a password of 8-16 digits');
            return;
        }
        setLoading(true);
        encrypt.setPublicKey(publicKey);
        login({
            userMobile: state.email.split(' ').join(''),
            password: encrypt.encrypt(state.password) || '',
        });
    };
    const login = async (prop: defaultInfo) => {
        api.login(prop, res => {
            const { code, message, data } = res;
            if (code === 200) {
                const token = data.token;
                window.sessionStorage.setItem('admin-satoken', token);
                window.sessionStorage.setItem('publicKey', publicKey);
                dispatch(setIsFree(data.subscriptionId === '0'));
                dispatch(setFreeDay(data.subscriptionTrialDays ? +data.subscriptionTrialDays : 0));
                // dispatch(setPassword(state.password));
                dispatch(setEmail(state.email));
                const isExpired = window.utils.isExpired(+data.subscriptionExpiryTime);
                getInfo(isExpired);
            } else {
                window.format.alert('error', message, code);
            }
            setLoading(false);
        });
    };
    useEffect(() => {
        api.getSecret(res => {
            const { code, message, data } = res;
            if (code === 200) {
                setPublicKey(data);
            } else {
                window.format.alert('error', message, code);
            }
        });
    }, []);
    return (
        <div className="login-main">
            <TopNav type={1}></TopNav>
            <div className="r-left login-box">
                <img src={logoIcon} className="logo-box" alt="" />
                <div className="login-form">
                    <h1>Log in now!</h1>
                    <div>
                        <p className="form-tip">Phone Number</p>
                        <div className="phone-inputBox">
                            <div className="left-iconBox r-left">
                                <div className="r-center auBox">
                                    <i className="au-icon"></i>
                                </div>
                                <span className="phone-codebox">(+61)</span>
                            </div>
                            <Input
                                className="r-input phone-box"
                                type="tel"
                                value={state.email}
                                maxLength={isBlur ? 12 : window.utils.isStartsWith04(state.email) ? 10 : 9}
                                onBlur={(e: any) => {
                                    setIsBlur(true);
                                    setState({ ...state, email: window.utils.formatNumber(state.email) });
                                }}
                                onFocus={(e: any) => {
                                    setIsBlur(false);
                                    e.target.value = e.target.value.replace(/\D/g, '');
                                    setState({ ...state, email: e.target.value.split(' ').join('') });
                                }}
                                onInput={(e: any) => {
                                    e.target.value = e.target.value.replace(/\D/g, '');
                                    setState({ ...state, email: e.target.value });
                                }}
                                placeholder="40 000 000"
                            />
                        </div>
                    </div>
                    <div className="baseSize">
                        <p className="form-tip">Password</p>
                        <Input
                            className="r-input"
                            placeholder="Enter your password"
                            type={psType}
                            maxLength={16}
                            value={state.password}
                            onInput={(e: any) => {
                                setState({ ...state, password: e.target.value });
                            }}
                            onKeyUp={e => enter(e)}
                            suffix={
                                psType === 'text' ? (
                                    <i className="eye-icon" onClick={() => setPsType('password')}></i>
                                ) : (
                                    <i className="eye-slash-icon" onClick={() => setPsType('text')}></i>
                                )
                            }
                        />
                    </div>
                    <p className="r-center forget-box" onClick={() => navigate('/sendSmsCode')}>
                        Forgot password
                    </p>
                    <Button
                        className="r-primary_btn"
                        disabled={!state.email && !state.password}
                        onClick={() => toHome()}
                        loading={loading}
                    >
                        Log in
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Login;
