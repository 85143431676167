import React from 'react';

import Index from '@/views/pages/index';
import IntroductionPage from '@/views/pages/Introduction-page';
import Home from '@/views/pages/home';
import SurveyRespond from '@/views/pages/home/surveyRespond';
import Prize from '@/views/pages/home/prize';
import EnterSuccess from '@/views/pages/home/enterSuccess';
import MemberOption from '@/views/pages/memberOption';
import MemberConfirm from '@/views/pages/memberOption/memberConfirm';
import Pay from '@/views/pages/memberOption/pay';
import PaySuccess from '@/views/pages/memberOption/paySuccess';
import Category from '@/views/pages/category';
import Profile from '@/views/pages/profile';
import EditProfile from '@/views/pages/profile/edit-profile';
import Membership from '@/views/pages/profile/membership';
import PointsRecord from '@/views/pages/profile/pointsRecord';
import DrawRecord from '@/views/pages/profile/drawRecord';
import PrizeRecord from '@/views/pages/profile/prizeRecord';

import WebSite from '@/views/home/index';
import SignUp from '@/views/login/signup';
import CompleteInformation from '@/views/login/completeInformation';
import Login from '@/views/login';
import SendSmsCode from '@/views/login/sendSmsCode';
import VerifyCode from '@/views/login/verifyCode';
import SetPassword from '@/views/login/setPassword';

export interface RouteConfigDeclaration {
    path: string;
    name?: string;
    exact?: boolean;
    auth?: boolean;
    isRedirect?: boolean;
    isDynamic?: boolean;
    loadingFallback?: string;
    component: any;
    childrens?: RouteConfigDeclaration[];
    isShowOnNav?: boolean;
    KeepAlive?: boolean;
    icon?: any;
    key?: number;
}
const routes: RouteConfigDeclaration[] = [
    {
        path: '/webSite',
        component: WebSite,
        exact: true,
        auth: false,
    },
    {
        path: '/login',
        component: Login,
        exact: true,
        auth: false,
    },
    {
        path: '/signUp',
        component: SignUp,
        exact: true,
        auth: false,
    },
    {
        path: '/completeInformation',
        component: CompleteInformation,
        exact: true,
        auth: false,
    },
    {
        path: '/sendSmsCode',
        component: SendSmsCode,
        exact: true,
        auth: false,
    },
    {
        path: '/verifyCode',
        component: VerifyCode,
        exact: true,
        auth: false,
    },
    {
        path: '/setPassword',
        component: SetPassword,
        exact: true,
        auth: false,
    },
    {
        path: '/*',
        component: Index,
        exact: false,
        childrens: [
            {
                name: 'Home',
                path: '/home',
                component: Home,
                exact: true,
                isShowOnNav: true,
                KeepAlive: false,
                icon: 'Home',
                key: 0,
            },
            {
                name: 'SurveyRespond',
                path: '/surveyRespond',
                component: SurveyRespond,
                exact: true,
                isShowOnNav: true,
                KeepAlive: false,
                icon: 'SurveyRespond',
                key: 0,
            },
            {
                name: 'Prize',
                path: '/prize',
                component: Prize,
                exact: true,
                isShowOnNav: true,
                KeepAlive: false,
                icon: 'Prize',
                key: 0,
            },
            {
                name: 'EnterSuccess',
                path: '/enterSuccess',
                component: EnterSuccess,
                exact: true,
                isShowOnNav: true,
                KeepAlive: false,
                icon: 'EnterSuccess',
                key: 0,
            },
            {
                name: 'MemberOption',
                path: '/memberOption',
                component: MemberOption,
                exact: true,
                isShowOnNav: true,
                KeepAlive: false,
                icon: 'MemberOption',
                key: 0,
            },
            {
                name: 'MemberConfirm',
                path: '/memberConfirm',
                component: MemberConfirm,
                exact: true,
                isShowOnNav: true,
                KeepAlive: false,
                icon: 'MemberConfirm',
                key: 0,
            },
            {
                name: 'Pay',
                path: '/pay',
                component: Pay,
                exact: true,
                isShowOnNav: true,
                KeepAlive: false,
                icon: 'Pay',
                key: 0,
            },
            {
                name: 'PaySuccess',
                path: '/paySuccess',
                component: PaySuccess,
                exact: true,
                isShowOnNav: true,
                KeepAlive: false,
                icon: 'PaySuccess',
                key: 0,
            },
            {
                name: 'Category',
                path: '/categories',
                component: Category,
                exact: true,
                isShowOnNav: true,
                KeepAlive: false,
                icon: 'Category',
                key: 1,
            },
            {
                name: 'Profile',
                path: '/profile',
                component: Profile,
                exact: true,
                isShowOnNav: true,
                KeepAlive: false,
                icon: 'Profile',
                key: 2,
            },
            {
                name: 'EditProfile',
                path: '/edit-profile',
                component: EditProfile,
                exact: true,
                isShowOnNav: true,
                KeepAlive: false,
                icon: 'EditProfile',
                key: 2,
            },
            {
                name: 'Membership',
                path: '/membership',
                component: Membership,
                exact: true,
                isShowOnNav: true,
                KeepAlive: false,
                icon: 'Membership',
                key: 2,
            },
            {
                name: 'PointsRecord',
                path: '/pointsRecord',
                component: PointsRecord,
                exact: true,
                isShowOnNav: true,
                KeepAlive: false,
                icon: 'PointsRecord',
                key: 2,
            },
            {
                name: 'DrawRecord',
                path: '/drawRecord',
                component: DrawRecord,
                exact: true,
                isShowOnNav: true,
                KeepAlive: false,
                icon: 'DrawRecord',
                key: 2,
            },
            {
                name: 'PrizeRecord',
                path: '/prizeRecord',
                component: PrizeRecord,
                exact: true,
                isShowOnNav: true,
                KeepAlive: false,
                icon: 'PrizeRecord',
                key: 2,
            },
            {
                name: 'IntroductionPage',
                path: '/introductionPage',
                component: IntroductionPage,
                exact: true,
                isShowOnNav: true,
                KeepAlive: false,
                icon: 'IntroductionPage',
                key: 0,
            },
        ],
    },
];

export default routes;
