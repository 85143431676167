import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import userinfoReducer from './userinfoSlice';
import stateReducer from './stateSlice';
import commonplaceReducer from './commonplace';

const reducers = combineReducers({
    userInfo: userinfoReducer,
    state: stateReducer,
    commonplace: commonplaceReducer,
});

const persistConfig = {
    key: 'root',
    storage,
    blacklist: [],
};

const persistedReducer = persistReducer(persistConfig, reducers);
const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk],
});
export default store;
export type RootState = ReturnType<typeof store.getState>;
// {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
