import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Divider } from 'antd';
import api from '@/api/home';
import './index.scss';
import logoIcon from '@/assets/img/logo@2x.png';
import { useSelector } from 'react-redux';
import { defaultInfo } from '@/typings/allType';
import JSEncrypt from 'jsencrypt';
import useLoading from '@/hook/useLoading';
const Pay = (props: any) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const type = searchParams.get('type') ?? '1'; // 1 引导页 2 升级会员选择套餐页
    const { mainLoading, setLoadingStatus } = useLoading();
    const encrypt = new JSEncrypt();
    const { deviceType } = useSelector((state: any) => state.state);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [payTotal, setPayTotal] = useState(0);
    const [stripePayToken, setStripePayToken] = useState('');
    const [payMemberInfo, setPayMemberInfo] = useState<any>({});
    const [state, setState] = useState({
        paymentRequest: null,
        showApplePay: false,
        showGooglePay: false,
        paidTotal: '0.00',
        stripePayToken: '',
        isShow: false,
    });
    const [cardInfo, setCardInfo] = useState({
        cardNumber: '',
        cardName: '',
        expire: '',
        expireYear: '',
        expireMonth: '',
        cvv: '',
    });
    const [paymentRequest, setPaymentRequest] = useState<any>(null);
    const showPayBtn = (total: number, clientSecret: string) => {
        const otherPk: any = window.sessionStorage.getItem('stripePkKey');
        const stripePkKey = process.env.STRIPE_PK ?? otherPk;
        // const stripePkKey =
        //     'pk_test_51Os0LtHrR8dfjtP2qZecsI4oF7ID3ZsuDOHUW2AlMJ7pM3ivn7XYki5AwZPZyvenP45wddsKbvylf2Wwe17lhC3Z00rN4ey7vQ';
        const stripe = (window as any).Stripe(stripePkKey.replaceAll('"', ''));
        const paymentRequest = stripe.paymentRequest({
            country: 'AU',
            currency: 'aud',
            total: {
                label: "what's your opinion",
                amount: Number(total),
            },
            requestPayerName: false,
            requestPayerEmail: false,
        });
        setPaymentRequest(paymentRequest);
        paymentRequest
            .canMakePayment()
            .then((result: defaultInfo) => {
                console.log(result);
                if (result) {
                    setState({
                        ...state,
                        showApplePay: result.applePay,
                        showGooglePay: result.googlePay,
                        isShow: true,
                    });
                } else {
                    setState({
                        ...state,
                        isShow: true,
                    });
                }
            })
            .catch(err => {
                setState({
                    ...state,
                    isShow: true,
                });
            });
        paymentRequest.on('paymentmethod', (ev: any) => {
            console.log(ev);
            stripe
                .confirmCardPayment(clientSecret, { payment_method: ev.paymentMethod.id }, { handleActions: false })
                .then(function (confirmResult: any) {
                    if (confirmResult.error) {
                        ev.complete('fail');
                        window.format.alert('error', 'Payment failed, please try again later');
                    } else {
                        ev.complete('success');
                        navigate(`/PaySuccess?type=${type}`);
                        // paymentMethodType	支付方式（google_pay/apple_pay）
                        // api.payByAppleOrGoogleSuccess({ param: window.sessionStorage.getItem('asskey'), paymentMethodType: state.showApplePay ? 'apple_pay' : 'google_pay' }).then(res => {
                        //     linkTo("/success");
                        // })
                    }
                });
        });
    };
    const getStripePayToken = (id: string) => {
        const pat = {
            subscriptionPackageId: id,
        };
        api.paymentWithOtherPay(pat, res => {
            const { data, code, message } = res;
            if (code === 200) {
                window.sessionStorage.setItem('paySuccessInfo', JSON.stringify(data));
                setPayTotal(data.totalAmount ? data.totalAmount : payMemberInfo.actual);
                setStripePayToken(data.clientSecret);
                try {
                    showPayBtn(data.totalAmount ? data.totalAmount : payMemberInfo.actual, data.clientSecret);
                } catch (error) {
                    setState({
                        ...state,
                        isShow: true,
                    });
                }
            } else {
                setState({
                    ...state,
                    isShow: true,
                });
                window.format.alert('error', message, code);
            }
        });
    };
    const nextStep = () => {
        if (cardInfo.cardNumber.split(' ').join('').length !== 16) {
            window.format.alert('error', 'The card number is invalid, please re-enter');
            return;
        }
        if (
            cardInfo.expire.split('/').length !== 2 ||
            +cardInfo.expire.split('/')[0] > 12 ||
            Number(`20${cardInfo.expire.split('/')[1]}`) < +window.utils.getCurremtYear()
        ) {
            window.format.alert('error', 'The expiration date is incorrect.');
            return;
        }
        setLoading(true);
        const obj = {
            number: cardInfo.cardNumber.split(' ').join(''),
            expMonth: cardInfo.expire.split('/')[0],
            expYear: cardInfo.expire.split('/')[1],
            cvv: cardInfo.cvv,
            name: cardInfo.cardName,
        };
        const publicKey = window.sessionStorage.getItem('publicKey') ?? '';
        encrypt.setPublicKey(publicKey);
        const pat = {
            cardInfoCiphertext: encrypt.encrypt(JSON.stringify(obj)),
            subscriptionPackageId: payMemberInfo.targetSubscriptionPackageId,
        };
        api.paymentWithCard(pat, res => {
            const { data, code, message } = res;
            setLoading(false);
            if (code === 200) {
                window.sessionStorage.setItem('paySuccessInfo', JSON.stringify(data));
                navigate(`/PaySuccess?type=${type}`);
            } else {
                window.format.alert('error', message, code);
            }
        });
    };
    useEffect(() => {
        const info = JSON.parse(window.sessionStorage.getItem('payMemberInfo') ?? '{}');
        if (info.targetSubscriptionPackageId) {
            setPayMemberInfo(info);
            getStripePayToken(info.targetSubscriptionPackageId);
        }
    }, []);
    useEffect(() => {
        setLoadingStatus(!state.isShow);
    }, [state.isShow]);
    return (
        <div>
            {state.isShow ? (
                <div className="member-confirm-main r-center">
                    <img src={logoIcon} className="logo-box" alt="" />
                    <div className="order-rightBox">
                        <section className="pay_content">
                            {state.showApplePay ? (
                                <div className="pay_btn r-center apppay" onClick={() => paymentRequest.show()}>
                                    <i className="apple_icon"></i>
                                    Apple Pay
                                </div>
                            ) : (
                                ''
                            )}
                            {state.showGooglePay ? (
                                <div
                                    className={`pay_btn r-center ${state.showApplePay ? 'mar17' : ''}`}
                                    onClick={() => paymentRequest.show()}
                                >
                                    <i className="google_icon"></i>
                                    Google Pay
                                </div>
                            ) : (
                                ''
                            )}
                            {state.showApplePay || state.showGooglePay ? (
                                <Divider className="r-divider" plain>
                                    Or
                                </Divider>
                            ) : (
                                ''
                            )}
                            <p className="pay_tips">Card holder full name</p>
                            <input
                                className="primaryPay_input"
                                type="text"
                                maxLength={100}
                                placeholder="Enter card holder full name"
                                onInput={(e: any) => {
                                    setCardInfo({ ...cardInfo, cardName: e.target.value });
                                }}
                            />
                            <p className="pay_tips">Card number</p>
                            <input
                                className="primaryPay_input marAuto"
                                type="tel"
                                maxLength={19}
                                onInput={(e: any) => {
                                    e.target.value = e.target.value.replace(/\D/g, '').replace(/....(?!$)/g, '$& ');
                                    setCardInfo({ ...cardInfo, cardNumber: e.target.value });
                                }}
                                placeholder="Credit or debit card number"
                            />
                            <div className="r-left">
                                <div>
                                    <p className="pay_tips">Expiry</p>
                                    <input
                                        className="primaryPay_input"
                                        type="tel"
                                        maxLength={5}
                                        onInput={(e: any) => {
                                            e.target.value = e.target.value
                                                .replace(/\D/g, '')
                                                .replace(/..(?!$)/g, '$&/');
                                            setCardInfo({ ...cardInfo, expire: e.target.value });
                                        }}
                                        placeholder="MM/YY"
                                    />
                                </div>
                                <div className="mar12_new">
                                    <p className="pay_tips">CVV</p>
                                    <input
                                        className="primaryPay_input"
                                        type="tel"
                                        maxLength={3}
                                        onInput={(e: any) => {
                                            e.target.value = e.target.value.replace(/\D/g, '');
                                            setCardInfo({ ...cardInfo, cvv: e.target.value });
                                        }}
                                        placeholder="123"
                                    />
                                </div>
                            </div>
                        </section>
                        <div className="r-left order-opeationBox">
                            <Button
                                className="r-primary_btn w100"
                                disabled={
                                    !cardInfo.cardName || !cardInfo.cardNumber || !cardInfo.cvv || !cardInfo.expire
                                }
                                loading={loading}
                                onClick={() => nextStep()}
                            >
                                Pay
                            </Button>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default Pay;
