import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, FreeMode } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import './index.scss';
import '@/style/swiper.scss';
import memberData from '@/utils/member';
const MemberCard = props => {
    const { deviceType } = useSelector((state: any) => state.state);
    const swiperRef = useRef<any>(null);
    useEffect(() => {
        const swiper = swiperRef.current?.swiper;
        if (swiper) {
            swiper.slideTo(props.initialSlideIndex, 0, false); // 设置默认选中的幻灯片
        }
    }, [props.initialSlideIndex]);
    return (
        <div className="subscriptionList-box introduction-card">
            {deviceType === 'pc' ? (
                <div className="subList-box r-left">
                    {memberData.subscriptionList.map(item => {
                        return (
                            <div className={`sub-card ${props.className}`} key={item.tit}>
                                <div className="subCard_top r-left">
                                    <img src={item.icon} className="sub-icon" alt="" />
                                    <div>
                                        <div className="sub-tit">{item.tit}</div>
                                        <div className="sub-price r-left">
                                            {item.price}
                                            {item.isMax ? <span className="maxIcBox">Maximum</span> : <></>}
                                        </div>
                                    </div>
                                    {item.isMost ? <div className="mostBox">MOST POPULAR</div> : ''}
                                </div>
                                <div className="sub_content">
                                    {item.infoList.map((val, i) => {
                                        return (
                                            <div className="r-left subInfo_box" key={i}>
                                                <i className="check_icon"></i>
                                                <span className="sub_tip">{val}</span>
                                            </div>
                                        );
                                    })}
                                    <div className="r-left subInfo_box sub_tipBtm">
                                        <i className="tip_icon"></i>
                                        <span className="sub_tip">{item.tip}</span>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            ) : (
                <Swiper
                    slidesPerView="auto"
                    spaceBetween={16}
                    ref={swiperRef}
                    pagination={{
                        clickable: true,
                    }}
                    freeMode={false}
                    modules={[Pagination]}
                    className="subList-box"
                >
                    {memberData.subscriptionList.map((item, i) => {
                        return (
                            <SwiperSlide>
                                <div className="sub-card" key={item.tit}>
                                    <div className="subCard_top r-left">
                                        <img src={item.icon} className="sub-icon" alt="" />
                                        <div>
                                            <div className="sub-tit">{item.tit}</div>
                                            <div className="sub-price r-left">
                                                {item.price}
                                                {item.isMax ? <span className="maxIcBox">Maximum</span> : <></>}
                                            </div>
                                        </div>
                                        {item.isMost ? <div className="mostBox">MOST POPULAR</div> : ''}
                                    </div>
                                    <div className="sub_content">
                                        {item.infoList.map((val, i) => {
                                            return (
                                                <div className="r-left subInfo_box" key={i}>
                                                    <i className="check_icon"></i>
                                                    <span className="sub_tip">{val}</span>
                                                </div>
                                            );
                                        })}
                                        <div className="r-left subInfo_box sub_tipBtm">
                                            <i className="tip_icon"></i>
                                            <span className="sub_tip">{item.tip}</span>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            )}
        </div>
    );
};
export default MemberCard;
