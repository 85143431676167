import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, FreeMode } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import './style/index.scss';
import '../../style/swiper.scss';
import { Button, Drawer } from 'antd';
import logo from '../../assets/img/logo.png';
import close from '../../assets/img/home/close.png';
import { useNavigate } from 'react-router-dom';
import { Popup } from 'react-vant';
import memberData from '@/utils/member';
import '../../assets/animate.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
const Home = () => {
    const navigate = useNavigate();
    const { deviceType } = useSelector((state: any) => state.state);
    const categoriesList = [
        { icon: require('../../assets/img/home/cate1.png'), msg: 'Federal Government' },
        { icon: require('../../assets/img/home/cate2.png'), msg: 'State Government' },
        { icon: require('../../assets/img/home/cate3.png'), msg: 'Local Government' },
        { icon: require('../../assets/img/home/cate4.png'), msg: 'JusticeSystem' },
        { icon: require('../../assets/img/home/cate5.png'), msg: 'Television' },
        { icon: require('../../assets/img/home/cate6.png'), msg: 'Radio' },
        { icon: require('../../assets/img/home/cate7.png'), msg: 'Print Media' },
        { icon: require('../../assets/img/home/cate8.png'), msg: 'National Importance' },
        { icon: require('../../assets/img/home/cate9.png'), msg: 'Sport' },
        { icon: require('../../assets/img/home/cate10.png'), msg: 'General' },
    ];
    const subscriptionList = memberData.subscriptionList;
    const memberOptionList = memberData.memberOptionList;
    const [open, setOpen] = useState(false);
    const onClose = () => {
        setOpen(false);
    };
    AOS.init({
        duration: 250, // 动画持续时间
        // easing: 'ease-in-out', // 缓动函数
        delay: 100, // 延迟时间
        once: false, // 仅触发一次
    });
    return (
        <div className="home-page">
            {deviceType === 'pc' ? (
                <div className="nav_top r-left">
                    <img src={require('../../assets/img/logo.png')} className="logo-box" alt="" />
                    <p className="top-tip">Already have an account?</p>
                    <Button className="r-primary_btn" onClick={() => navigate('/login')}>
                        Log In
                    </Button>
                </div>
            ) : (
                <div className="nav_top r-left">
                    <i className="menu-icon" onClick={() => setOpen(true)}></i>
                    <Button className="top_btn r-center" onClick={() => navigate('/signUp')}>
                        Sign Up
                    </Button>
                </div>
            )}
            <section className="content-box">
                <div className="r-center">
                    <img
                        src={require('../../assets/img/logo.png')}
                        className="logo-large"
                        data-aos="fade-down"
                        alt=""
                    />
                </div>
                <div data-aos="fade-up">
                    <div className="content-tit">
                        <span>Welcome to</span>
                        <span className="content-col">Australia's foremost public opinion poll website!</span>
                    </div>
                    <p className="top-tip">We ask your opinion and make sure everyone knows about it</p>
                </div>
                <div className="card-box mar80 r-center">
                    <img
                        src={require('../../assets/img/home/card1.png')}
                        className="card-img"
                        data-aos="fade-right"
                        alt=""
                    />
                    <div className="rowbox"></div>
                    <div className="card-rightBox" data-aos="fade-left">
                        <h3 style={{ padding: 0 }}>How it works</h3>
                        <div className="r-left cardmsg-box work-center">
                            <img src={require('../../assets/img/home/work1.png')} className="card-icon" alt="" />
                            <span className="work_first">Become a member</span>
                        </div>
                        <div className="r-left cardmsg-box">
                            <img src={require('../../assets/img/home/work2.png')} className="card-icon" alt="" />
                            Be part of a national survey/database that has answers to some very important and debatable
                            question.
                        </div>
                        <div className="r-left cardmsg-box">
                            <img src={require('../../assets/img/home/work3.png')} className="card-icon" alt="" />
                            Accumulate points and devise a strategy for entering our prize draws, this is where you
                            could win BIG.
                        </div>
                        <div className="r-left cardmsg-box">
                            <img src={require('../../assets/img/home/work4.png')} className="card-icon" alt="" />
                            Have your voice heard - know what the rest of Australia thinks.
                        </div>
                        <div className="sign-btn r-center" onClick={() => navigate('/signUp')}>
                            Sign Up
                        </div>
                    </div>
                </div>
                <div className={`card-box r-center ${deviceType === 'mb' ? 'revice' : ''}`}>
                    <div className="card-rightBox" data-aos="fade-right">
                        <h3 className="marB20" style={{ padding: 0 }}>
                            Survey Categories
                        </h3>
                        <div className="r-left category-listBox">
                            {categoriesList.map((item, i) => {
                                return (
                                    <div className="r-left category-box cardmsg-box" key={`category-boxHome-${i}`}>
                                        <img src={item.icon} className="category-icon" alt="" />
                                        {item.msg}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="rowbox"></div>
                    <img
                        src={require('../../assets/img/home/card2.png')}
                        className="card-img"
                        data-aos="fade-left"
                        alt=""
                    />
                </div>
                <div className="mar80">
                    <img src={require('../../assets/img/home/big1.png')} className="max-bg" data-aos="fade-up"></img>
                </div>
                <h3>Prizes available for members</h3>
                {deviceType === 'pc' ? (
                    <div className="subList-box r-left" data-aos="fade-up">
                        {subscriptionList.map((item, i) => {
                            return (
                                <div className="sub-card" key={`sub-cardHome-${i}`}>
                                    <div className="subCard_top r-left">
                                        <img src={item.icon} className="sub-icon" alt="" />
                                        <div>
                                            <div className="sub-tit">{item.tit}</div>
                                            <div className="sub-price r-left">
                                                {item.price}
                                                {item.isMax ? <span className="maxIcBox">Maximum</span> : <></>}
                                            </div>
                                        </div>
                                        {item.isMost ? <div className="mostBox">MOST POPULAR</div> : ''}
                                    </div>
                                    <div className="sub_content">
                                        {item.infoList.map((val, i) => {
                                            return (
                                                <div className="r-left subInfo_box" key={`subInfo_box-${i}`}>
                                                    <i className="check_icon"></i>
                                                    <span className="sub_tip">{val}</span>
                                                </div>
                                            );
                                        })}
                                        <div className="r-left subInfo_box sub_tipBtm" style={{ marginBottom: 0 }}>
                                            <i className="tip_icon"></i>
                                            <span className="sub_tip">{item.tip}</span>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <Swiper
                        slidesPerView="auto"
                        spaceBetween={10}
                        pagination={{
                            clickable: true,
                        }}
                        freeMode={false}
                        modules={[Pagination]}
                        className="subList-box"
                        data-aos="fade-up"
                    >
                        {subscriptionList.map((item, i) => {
                            return (
                                <SwiperSlide key={`home-swiper-${i}`}>
                                    <div className="sub-card" key={item.tit}>
                                        <div className="subCard_top r-left">
                                            <img src={item.icon} className="sub-icon" alt="" />
                                            <div>
                                                <div className="sub-tit">{item.tit}</div>
                                                <div className="sub-price r-left">
                                                    {item.price}
                                                    {item.isMax ? <span className="maxIcBox">Maximum</span> : <></>}
                                                </div>
                                            </div>
                                            {item.isMost ? <div className="mostBox">MOST POPULAR</div> : ''}
                                        </div>
                                        <div className="sub_content">
                                            {item.infoList.map((val, i) => {
                                                return (
                                                    <div className="r-left subInfo_box" key={`subInfo-swiper${i}`}>
                                                        <i className="check_icon"></i>
                                                        <span className="sub_tip">{val}</span>
                                                    </div>
                                                );
                                            })}
                                            <div className="r-left subInfo_box sub_tipBtm" style={{ marginBottom: 0 }}>
                                                <i className="tip_icon"></i>
                                                <span className="sub_tip">{item.tip}</span>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                )}
                <div className="mar80">
                    <img
                        src={require('../../assets/img/home/big2.png')}
                        className="max-bg max-mb"
                        data-aos="fade-right"
                    ></img>
                </div>
                <h3>Membership Option</h3>
                {deviceType === 'pc' ? (
                    <div className="memberOption-box r-left" data-aos="fade-left">
                        {memberOptionList.map((item, i) => {
                            return (
                                <div className="member-card" key={`memberCard-${i}`}>
                                    {i === 1 ? <div className="mostBox">MOST POPULAR</div> : ''}
                                    <div className="mem-tit">{item.tit}</div>
                                    {item.priceList.map((val, k) => {
                                        return (
                                            <div
                                                key={`priceCard-home${k}-${i}`}
                                                className={`price-card ${k === 1 ? 'mar12' : ''}`}
                                            >
                                                <div className="price-date">{val.date}</div>
                                                <div className="r-left">
                                                    <div className="price-box r-left">
                                                        <span className="priceLef">$ </span>
                                                        <span className="priceNum">{val.price}</span>
                                                    </div>
                                                    <div className="price-pts">{val.pts}</div>
                                                </div>
                                                <div className="price-tip">{val.tip}</div>
                                                {val.dicount ? <div className="discountBox">{val.dicount}</div> : ''}
                                            </div>
                                        );
                                    })}
                                    <div className="sign-btn r-center" onClick={() => navigate('/login')}>
                                        Sign Up
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <Swiper
                        slidesPerView="auto"
                        spaceBetween={10}
                        pagination={{
                            clickable: true,
                        }}
                        freeMode={false}
                        modules={[Pagination]}
                        className="memberOption-box"
                        data-aos="fade-left"
                    >
                        {memberOptionList.map((item, i) => {
                            return (
                                <SwiperSlide key={`swiperMb-${i}`}>
                                    <div
                                        className={`member-card ${i === 1 ? 'other-color' : ''}`}
                                        key={`member-cardHome-${i}`}
                                    >
                                        {i === 1 ? <div className="mostBox">MOST POPULAR</div> : ''}
                                        <div className="mem-tit">{item.tit}</div>
                                        {item.priceList.map((val, k) => {
                                            return (
                                                <div
                                                    className={`price-card ${k === 1 ? 'mar12' : ''}`}
                                                    key={`price-cardHome${i}-${k}`}
                                                >
                                                    <div className="price-date">{val.date}</div>
                                                    <div className="r-left">
                                                        <div className="price-box r-left">
                                                            <span className="priceLef">$ </span>
                                                            <span className="priceNum">{val.price}</span>
                                                        </div>
                                                        <div className="price-pts">{val.pts}</div>
                                                    </div>
                                                    <div className="price-tip">{val.tip}</div>
                                                    {val.dicount ? (
                                                        <div className="discountBox">{val.dicount}</div>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            );
                                        })}
                                        <div
                                            className="sign-btn swiper-sign r-center"
                                            onClick={() => navigate('/login')}
                                        >
                                            Sign Up
                                        </div>
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                )}

                <img
                    src={require('../../assets/img/home/big3.png')}
                    className="max-bg max-mb3"
                    data-aos="fade-down"
                ></img>
            </section>
            <Popup visible={open} className="r-drawer" onClose={() => onClose()} position="left">
                <div className="r-left top-close">
                    <img src={logo} className="drawerLogo" alt="" />
                    <img src={close} className="drawerClose" onClick={() => setOpen(false)} alt="" />
                </div>
                <div className="drawer-tip">Already have an account?</div>
                <div className="baseSize">
                    <Button className="r-primary_btn" onClick={() => navigate('/login')}>
                        Log In
                    </Button>
                </div>
            </Popup>
        </div>
    );
};
export default Home;
