import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import api from '@/api/profile';
import './index.scss';
import logoIcon from '@/assets/img/logo@2x.png';
import { useSelector } from 'react-redux';
import { Divider, Skeleton, Spin } from 'antd';
import useLoading from '@/hook/useLoading';
const DrawRecord = (props: any) => {
    const { deviceType } = useSelector((state: any) => state.state);
    const navigate = useNavigate();
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [maxPages, setMaxPages] = useState(1);
    const [loading, setLoading] = useState(false);
    const [noshowPointList, setNoshowPointList] = useState<any>([]);
    const [pointsRecordList, setPointsRecordList] = useState<any>([]);
    const groupByYearMonth = records => {
        const groupedData = {};
        for (const record of records) {
            const { yearMonth } = record;
            if (!groupedData[yearMonth]) {
                groupedData[yearMonth] = {
                    yearMonth,
                    month: window.utils.foramtMonthTime(yearMonth),
                    list: [],
                };
            }
            groupedData[yearMonth].list.push(record);
        }
        const result = Object.values(groupedData);
        return result;
    };
    const getList = (pageNow?: number) => {
        const pat = { pageNum: pageNow ? pageNow : page, pageSize: 20 };
        api.getUserlLotteryList(pat, res => {
            const { data, code, message } = res;
            setLoading(false);
            if (code === 200) {
                data.records.forEach(item => {
                    item.createTime = item.createTime ? window.utils.foramtTimeDMYHM_easy(+item.createTime) : '-';
                    item.drawPoints = item.drawPoints ? window.utils.formatPonit(item.drawPoints) : '0';
                    item.titleShow =
                        item.prizeStatus === 1 ? 'Awaiting' : item.prizeStatus === 3 ? 'Prize winning' : 'Fail to win';
                });
                const arr: any = groupByYearMonth([...noshowPointList, ...data.records]);
                setTotal(data.total);
                setMaxPages(data.pages);
                if (pageNow !== 1) {
                    setNoshowPointList([...noshowPointList, ...data.records]);
                } else {
                    setNoshowPointList([...data.records]);
                }
                console.log('====================================');
                console.log(arr);
                console.log('====================================');
                setPointsRecordList([...arr]);
            } else {
                window.format.alert('error', message, code);
            }
        });
    };
    const loadMoreData = () => {
        if (loading) {
            return;
        }
        setLoading(true);
        getList(page + 1);
        setPage(page + 1);
    };
    useEffect(() => {
        setLoading(true);
        getList(1);
    }, []);
    const colorShow = {
        'Prize winning': '#3ECC92',
        'Fail to win': '#666666',
        Awaiting: '#FFFFFF',
    };
    return (
        <div className="membership-main pointrecord-main">
            <div className="zIndexTop">
                <div className="r-center">
                    <img src={logoIcon} className="logo-box" alt="" />
                </div>
                <h1 className="pointH1">Draw record</h1>
                <section className="record-main">
                    <InfiniteScroll
                        dataLength={total}
                        next={loadMoreData}
                        hasMore={noshowPointList.length < total}
                        loader={
                            <Divider plain style={{ color: '#fff' }}>
                                <Spin
                                    delay={500}
                                    indicator={<div className="loading_icon" style={{ fontSize: 30 }}></div>}
                                    size="large"
                                ></Spin>
                            </Divider>
                        }
                        scrollableTarget="scrollableDiv"
                    >
                        {pointsRecordList.length ? (
                            pointsRecordList.map((val, i) => {
                                return (
                                    <div key={i}>
                                        <p className="month-box">{val.month ?? '-'}</p>
                                        <div className="r-left draw-box" style={{ alignItems: 'flex-start' }}>
                                            {val.list.map((item, k) => {
                                                return (
                                                    <div className={`record-card draw-card`} key={k}>
                                                        <div className="r-left quesInfo">{item.prizeName ?? '-'}</div>
                                                        <div
                                                            className="r-left get-time drawpointNum"
                                                            style={{ opacity: 1 }}
                                                        >
                                                            {item.drawPoints}
                                                            {item.drawPoints > 1 ? ' points' : ' points'}
                                                            {item.buyNum ? (
                                                                <span className="marLeft">x{item.buyNum}</span>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </div>
                                                        <div className="r-left get-time prizeGet_time">
                                                            {item.createTime ?? '-'}
                                                            <span
                                                                className="prizeStatus marLeft"
                                                                style={{ color: colorShow[item.titleShow] }}
                                                            >
                                                                {/*Prize winning, Fail to win, Awaiting */}
                                                                {item.titleShow ?? 'Awaiting'}
                                                            </span>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div className="r-center noMessage-box centerEmptyText">
                                {loading ? (
                                    <Divider plain style={{ color: '#fff' }}>
                                        <Spin
                                            delay={500}
                                            indicator={<div className="loading_icon" style={{ fontSize: 30 }}></div>}
                                            size="large"
                                        ></Spin>
                                    </Divider>
                                ) : (
                                    'No data'
                                )}
                            </div>
                        )}
                    </InfiniteScroll>
                </section>
            </div>
        </div>
    );
};

export default DrawRecord;
