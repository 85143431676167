import React, { useState, useEffect } from 'react';
import LayoutMain from '@/layout';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'react-vant';
import useLoading from '@/hook/useLoading';
import apiProfile from '@/api/profile';
import { useAppDispatch } from '@/store/hooks';
import { setProfileInfo } from '@/store/stateSlice';
const Main = (props: any) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { mainLoading, setLoadingStatus } = useLoading();
    const [token, setToken] = useState(sessionStorage.getItem('admin-satoken'));
    useEffect(() => {
        if (!token) navigate('/webSite');
    });
    const [toastTarget, setToastTarget] = useState<any>(null);
    useEffect(() => {
        function rightCartData() {
            const item = JSON.parse(sessionStorage.getItem('admin-satoken') as any);
            if (item) {
                setToken(item);
            }
        }
        window.addEventListener('storage', rightCartData);

        return () => {
            window.removeEventListener('storage', rightCartData);
        };
    }, []);
    const getInfo = () => {
        apiProfile.getUserProfileInfo(res => {
            const { data, code, message } = res;
            if (code === 200) {
                if (data && data !== null) {
                    data.subscriptionId = data.subscriptionId;
                    data.isExpired = window.utils.isExpired(+data.subscriptionExpiryTime);
                    data.point = window.utils.formatPonit(data.point ?? 0);
                    data.subscriptionExpiryTimeShow =
                        data.subscriptionExpiryTimeShow !== null
                            ? window.utils.foramtTimeDMYTime(data.subscriptionExpiryTimeShow)
                            : '-';

                    dispatch(setProfileInfo({ ...data }));
                    if (data.isExpired) {
                        navigate('/introductionPage?type=2');
                    }
                }
                setLoadingStatus(false);
            } else {
                window.format.alert('error', message, code);
            }
        });
    };
    useEffect(() => {
        setLoadingStatus(false);
        if (token) {
            getInfo();
        }
    }, []);
    useEffect(() => {
        if (mainLoading) {
            const toast = Toast({
                type: 'loading',
                message: '',
                duration: 0,
            });
            setToastTarget(toast);
        } else {
            toastTarget && toastTarget.clear();
        }
    }, [mainLoading]);
    return (
        <>
            {token ? (
                <div>
                    <LayoutMain routes={props.routes ? props.routes : []} />
                </div>
            ) : (
                ''
            )}
        </>
    );
};

export default Main;
