import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import './index.scss';
import logoIcon from '@/assets/img/logo@2x.png';
import { useSelector } from 'react-redux';
const MemberConfirm = (props: any) => {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const type = searchParams.get('type') ?? '1'; // 1 引导页 2 升级会员选择套餐页
    const { deviceType, profileInfo } = useSelector((state: any) => state.state);
    const [memberType, setMemberType] = useState(2);
    const [payMemberInfo, setPayMemberInfo] = useState<any>({});
    const [orderInfo, setOrderInfo] = useState([
        {
            type: 0,
            title: 'New order',
            description: '',
        },
        {
            type: 1,
            title: 'Membership upgrade',
            description: 'Upgrade member only to make up the difference',
        },
        {
            type: 2,
            title: 'Membership extension',
            description: 'Members only need to make up the difference for extension',
        },
    ]);
    const nextStep = (key: number) => {
        if (key === 1) {
            navigate(-1);
            return;
        }
        navigate(`/pay?type=${type}`);
    };
    useEffect(() => {
        const info = JSON.parse(window.sessionStorage.getItem('payMemberInfo') ?? '{}');
        if (info.targetSubscriptionPackageId) {
            if (info.status === 0 || info.status === 2) {
                setMemberType(0);
            } else if (info.status === 3) {
                setMemberType(1);
            } else {
                setMemberType(2);
            }
            // if (info.currentSubscriptionPackageId === null) {
            //     setMemberType(0);
            // } else if (profileInfo.subscriptionId !== info.subscriptionId) {
            //     setMemberType(1);
            // } else {
            //     setMemberType(2);
            // }
            setPayMemberInfo(info);
        }
    }, []);
    return (
        <div className="member-confirm-main r-center">
            <img src={logoIcon} className="logo-box" alt="" />
            <div className="order-rightBox">
                <h3 className="order-tit">{orderInfo[memberType].title}</h3>
                {memberType ? (
                    <p className="order-tip orderTop-tip">{orderInfo[memberType].description}</p>
                ) : (
                    <div className="rouBox"></div>
                )}
                <div className="orderInfo-box">
                    {memberType ? (
                        <div>
                            <div className="r-left order-tip marb12">
                                Current
                                <span className="order-msg order-tip">
                                    {payMemberInfo.currentSubscriptionName ?? 'Glod'} -{' '}
                                    {payMemberInfo.currentSubscriptionDuration ?? '1'} Year
                                </span>
                            </div>
                            <div className="r-left order-tip marb12">
                                Price
                                <span className="order-msg order-tip">
                                    ${payMemberInfo.currentSubscriptionAmount ?? '0.00'}
                                </span>
                            </div>
                            <div className="r-left order-tip marb12">
                                Points
                                <span className="order-msg order-tip">{payMemberInfo.currentPoint ?? 0} PTS</span>
                            </div>
                            <div className="r-left order-tip marb20">
                                Exp. Date
                                <span className="order-msg order-tip">
                                    {payMemberInfo.currentSubscriptionEndTime ?? '-'}
                                </span>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                    <div className="r-left order-tip marb12">
                        Target
                        <span className="order-msg order-tip">
                            {payMemberInfo.title ?? 'Gold'} - {payMemberInfo.targetSubscriptionDuration ?? '1'} Year
                        </span>
                    </div>
                    <div className="r-left order-tip marb12">
                        Price
                        <span className="order-msg order-tip">${payMemberInfo.targetSubscriptionAmount ?? '0.00'}</span>
                    </div>
                    <div className="r-left order-tip marb12">
                        Points
                        <span className="order-msg order-tip">{payMemberInfo.targetPoint ?? 0} PTS</span>
                    </div>
                    {!memberType ? (
                        <div className="r-left order-tip marb12">
                            Start Date
                            <span className="order-msg order-tip">
                                {payMemberInfo.targetSubscriptionStartTime ?? '-'}
                            </span>
                        </div>
                    ) : (
                        ''
                    )}
                    <div className="r-left order-tip marb12">
                        Exp. Date
                        <span className="order-msg order-tip">{payMemberInfo.targetSubscriptionEndTime ?? '-'}</span>
                    </div>
                    <div className="order-line"></div>
                    <div className="r-left order-tip marb12">
                        Actual
                        <span className="order-msg order-tip">${payMemberInfo.actual ?? '0.00'}</span>
                    </div>
                </div>
                <div className="r-left order-opeationBox">
                    <Button className={`r-gray_btn w220`} onClick={() => nextStep(1)}>
                        Back
                    </Button>
                    <Button className="r-primary_btn w220" onClick={() => nextStep(2)}>
                        Pay
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default MemberConfirm;
