import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Divider, Spin } from 'antd';
import api from '@/api/profile';
import './index.scss';
import logoIcon from '@/assets/img/logo@2x.png';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
const Membership = (props: any) => {
    const { deviceType } = useSelector((state: any) => state.state);
    const navigate = useNavigate();
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [maxPages, setMaxPages] = useState(1);
    const [loading, setLoading] = useState(false);
    const [noshowPointList, setNoshowPointList] = useState<any>([]);
    const [pointsRecordList, setPointsRecordList] = useState<any>([]);
    const groupByYearMonth = records => {
        const groupedData = {};
        for (const record of records) {
            const { yearMonth } = record;
            if (!groupedData[yearMonth]) {
                groupedData[yearMonth] = {
                    yearMonth,
                    month: window.utils.foramtMonthTime(yearMonth),
                    list: [],
                };
            }
            groupedData[yearMonth].list.push(record);
        }
        const result = Object.values(groupedData);
        return result;
    };
    const getList = (pageNow?: number) => {
        const pat = { pageNum: pageNow ? pageNow : page, pageSize: 20 };
        api.getUserPointList(pat, res => {
            const { data, code, message } = res;
            setLoading(false);
            if (code === 200) {
                data.records.forEach(item => {
                    item.point = item.point ? window.utils.formatPonit(+item.point) : '0';
                    item.createTime = item.createTime ? window.utils.foramtTimeDMYHM_easy(+item.createTime) : '-';
                    item.titleShow =
                        item.types === 0
                            ? 'Answer question'
                            : item.types === 1
                            ? 'Subscription member'
                            : item.types === 2
                            ? 'Sign up reward'
                            : 'Prize draw';
                });
                const arr: any = groupByYearMonth([...noshowPointList, ...data.records]);
                setTotal(data.total);
                setMaxPages(data.pages);
                if (pageNow !== 1) {
                    setNoshowPointList([...noshowPointList, ...data.records]);
                } else {
                    setNoshowPointList([...data.records]);
                }
                setPointsRecordList([...arr]);
            } else {
                window.format.alert('error', message, code);
            }
        });
    };
    const loadMoreData = () => {
        if (loading) {
            return;
        }
        setLoading(true);
        getList(page + 1);
        setPage(page + 1);
    };
    useEffect(() => {
        setLoading(true);
        getList(1);
    }, []);
    return (
        <div className="membership-main pointrecord-main emptyHeight">
            <div className="zIndexTop">
                <div className="r-center">
                    <img src={logoIcon} className="logo-box" alt="" />
                </div>
                <h1 className="pointH1">Points record</h1>
                <section className="record-main">
                    <InfiniteScroll
                        dataLength={total}
                        next={loadMoreData}
                        hasMore={noshowPointList.length < total}
                        loader={
                            <Divider plain style={{ color: '#fff' }}>
                                <Spin
                                    delay={500}
                                    indicator={<div className="loading_icon" style={{ fontSize: 30 }}></div>}
                                    size="large"
                                ></Spin>
                            </Divider>
                        }
                        // endMessage={
                        //     <Divider plain style={{ color: '#fff' }}>
                        //         It is all, nothing more
                        //     </Divider>
                        // }
                        scrollableTarget="scrollableDiv"
                    >
                        {pointsRecordList.length ? (
                            pointsRecordList.map((val, k) => {
                                return (
                                    <div key={k}>
                                        <p className="month-box">{val.month ?? '-'}</p>
                                        <div className="r-left record-list" style={{ alignItems: 'flex-start' }}>
                                            {val.list.map((item, i) => {
                                                return (
                                                    <div className={`record-card`} key={`ponitBox-${i}`}>
                                                        <div className="r-left quesInfo">
                                                            {item.titleShow}
                                                            <span className="pointNum marLeft">
                                                                {item.types !== 3 ? '+' : '-'} {item.point}
                                                            </span>
                                                        </div>
                                                        <p className="get-time">{item.createTime ?? '-'}</p>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div className="r-center noMessage-box centerEmptyText">
                                {loading ? (
                                    <Divider plain style={{ color: '#fff' }}>
                                        <Spin
                                            delay={500}
                                            indicator={<div className="loading_icon" style={{ fontSize: 30 }}></div>}
                                            size="large"
                                        ></Spin>
                                    </Divider>
                                ) : (
                                    'No data'
                                )}
                            </div>
                        )}
                    </InfiniteScroll>
                </section>
            </div>
        </div>
    );
};

export default Membership;
