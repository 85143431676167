import { createSlice, PayloadAction } from '@reduxjs/toolkit';
interface userInfoObj {
    email: string;
    password: string;
    roleTitle: string;
}
const initialState: userInfoObj = {
    email: '',
    password: '',
    roleTitle: '',
};

export const userInfoSlice = createSlice({
    name: 'userInfo',
    initialState,
    reducers: {
        setEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload;
        },
        setPassword: (state, action: PayloadAction<string>) => {
            state.password = action.payload;
        },
    },
});

export const { setEmail, setPassword } = userInfoSlice.actions;

export default userInfoSlice.reducer;
