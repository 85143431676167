import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Modal } from 'antd';
import api from '@/api/home';
import './index.scss';
import logoIcon from '@/assets/img/logo@2x.png';
import { useSelector } from 'react-redux';
import { defaultInfo } from '@/typings/allType';
import memberData from '@/utils/member';
const MemberOption = (props: any) => {
    const memberOptionList = memberData.memberOptionList;
    const { deviceType, profileInfo } = useSelector((state: any) => state.state);
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const type = searchParams.get('type') ?? '1'; // 1 引导页 2 升级会员选择套餐页
    const subscriptionId = searchParams.get('subscriptionId') ?? '0'; // 订阅id (之前选择的订阅)
    const subscriptionPackageId = searchParams.get('subscriptionPackageId') ?? '0'; // 套餐id（免费为0 其他的只显示和当前套餐相同时间的套餐）
    const [memberList, setMemberList] = useState<defaultInfo[]>([]);
    const [title, setTitle] = useState<any>('');
    const [loading, setLoading] = useState(false);
    const selectMember = (key: number) => {
        memberList.forEach((item, i) => {
            item.isActive = i === key;
        });
        setMemberList([...memberList]);
    };
    const getPayKey = () => {
        api.getStripePayKey(res => {
            const { data, code, message } = res;
            if (code === 200) {
                window.sessionStorage.setItem('stripePkKey', data ?? '');
            } else {
                window.format.alert('error', message, code);
            }
        });
    };
    const toPay = () => {
        // window.utils.alert('error', 'You have a membership subscription pending for [Bronze-1 Year]');
        setLoading(true);
        const nowSubscriptionPackageId =
            memberList.find(v => v.isActive)?.subscriptionPackageId ?? subscriptionPackageId;
        api.getMemberPaymentInfo({ subscriptionPackageId: nowSubscriptionPackageId }, res => {
            const { data, code, message } = res;
            setLoading(false);
            if (code === 200) {
                console.log(data);
                if (data && data !== null) {
                    data.actual = window.utils.formatMoney(data.actual ?? '0.00');
                    data.currentPoint = window.utils.formatPonit(data.currentPoint ?? 0);
                    data.currentSubscriptionAmount = window.utils.formatMoney(data.currentSubscriptionAmount ?? 0);
                    data.currentSubscriptionEndTime =
                        data.currentSubscriptionEndTime !== null
                            ? window.utils.foramtTimeDMYTime(+data.currentSubscriptionEndTime)
                            : '-';
                    data.targetSubscriptionStartTime =
                        data.targetSubscriptionStartTime !== null
                            ? window.utils.foramtTimeDMYTime(+data.targetSubscriptionStartTime)
                            : '-';
                    data.targetPoint = window.utils.formatPonit(data.targetPoint ?? 0);
                    data.targetSubscriptionAmount = window.utils.formatMoney(data.targetSubscriptionAmount ?? 0);
                    data.targetSubscriptionEndTime =
                        data.targetSubscriptionEndTime !== null
                            ? window.utils.foramtTimeDMYTime(+data.targetSubscriptionEndTime)
                            : '-';
                    data.subscriptionId = subscriptionId;
                    data.nowSubscriptionPackageId = nowSubscriptionPackageId;
                    data.title = title;
                    window.sessionStorage.setItem('payMemberInfo', JSON.stringify(data));
                    navigate(`/memberConfirm?type=${type}`);
                }
            } else {
                window.format.alert('error', message, code);
            }
        });
    };
    const subscriptionPackageIdList = [
        [1, 4, 7],
        [2, 5, 8],
        [3, 6, 9],
    ];
    useEffect(() => {
        getPayKey();
        if (Number(subscriptionId) !== 0) {
            // 升级
            const nowInfo: any = memberOptionList.find(v => v.subscriptionId === Number(subscriptionId));
            if (Number(profileInfo.subscriptionPackageId) !== 0 && !profileInfo.isExpired) {
                const i = subscriptionPackageIdList.findIndex(v =>
                    v.includes(Number(profileInfo.subscriptionPackageId))
                );
                let optionList: any = [];
                if (
                    +nowInfo.subscriptionId === Number(profileInfo.subscriptionId) ||
                    Number(profileInfo.subscriptionId) > +nowInfo.subscriptionId
                ) {
                    // 相同订阅全部套餐可选
                    optionList = nowInfo?.priceList.map(item => {
                        return Object.assign(item, { isActive: false });
                    });
                } else {
                    // 不同订阅 只能选时间相同套餐
                    optionList = [Object.assign(nowInfo?.priceList[i], { isActive: false })];
                }
                setTitle(nowInfo?.tit);
                setMemberList([...optionList]);
                return;
            }
            const optionList: any = nowInfo?.priceList.map(item => {
                return Object.assign(item, { isActive: false });
            });
            setTitle(nowInfo?.tit);
            setMemberList(optionList);
        } else {
            // 首次开通
            const optionList: any = memberOptionList[0].priceList.map(item => {
                return Object.assign(item, { isActive: false });
            });
            setTitle(memberOptionList[0].tit);
            setMemberList(optionList);
        }
    }, []);
    return (
        <div className="member-main">
            <div className="zIndexTop">
                <div className="r-center">
                    <img src={logoIcon} className="logo-box" alt="" />
                </div>
                <div className="member-content">
                    <h1 className="memtop-t">{title ?? '-'}</h1>
                    <div className="r-left memList-box" style={{ justifyContent: 'center' }}>
                        {memberList.map((val, i) => {
                            return (
                                <div
                                    key={`memoptionPrice-${i}`}
                                    className={`price-card ${val.isActive ? 'active' : ''}`}
                                    onClick={() => {
                                        selectMember(i);
                                    }}
                                >
                                    <div className="price-date">{val.date}</div>
                                    <div className="r-left">
                                        <div className="price-box r-left">
                                            <span className="priceLef">$ </span>
                                            <span className="priceNum">{val.price}</span>
                                        </div>
                                        <div className="price-pts">{val.pts}</div>
                                    </div>
                                    <div className="price-tip">{val.tip}</div>
                                    {val.dicount ? <div className="discountBox">{val.dicount}</div> : ''}
                                </div>
                            );
                        })}
                    </div>
                    <div className="opeation-box">
                        <div className="r-center">
                            <Button
                                className="r-primary_btn pay-btn"
                                disabled={memberList.findIndex(v => v.isActive) === -1}
                                loading={loading}
                                onClick={() => toPay()}
                            >
                                Pay
                            </Button>
                        </div>
                        <div className="r-center">
                            <p
                                className="other-btn"
                                onClick={() => {
                                    navigate(`/introductionPage?type=${type}`);
                                }}
                            >
                                Other membership option
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MemberOption;
