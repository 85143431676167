import axios from '../utils/index';
import { Pat } from '@/typings/request';
const categoryApi = {
    getCategiryList(callback: (T: any) => void) {
        axios
            .get(`/user-question-category-entity/list`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    selectUserCategory(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/user-question-category-entity/select`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
};
export default categoryApi;
