import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: false,
    class: 'default-loading',
    text: 'Deleting',
};

const commonplaceSlice = createSlice({
    name: 'commonplace',
    initialState,
    reducers: {
        whetherSpinning(state, action) {
            state.loading = action.payload.loading;
            state.class = action.payload.class;
            state.text = action.payload.text;
        },
    },
});

export const { whetherSpinning } = commonplaceSlice.actions;

export default commonplaceSlice.reducer;
