import React, { useEffect, useState } from 'react';
import './index.scss';
const CodeInput = (props: any) => {
    const [codeArrNew, setCodeArrNew] = useState(['', '', '', '']);
    const [opeationIndex, setOpeationIndex] = useState(0);
    const showAmount = [{ ref: 'codeOne' }, { ref: 'codeTwo' }, { ref: 'codeThe' }, { ref: 'codeFor' }];
    const nextFocus = ($event, index) => {
        /* eslint-disable */
        const nowEle: any = document.getElementById(showAmount[index].ref as string);
        if (nowEle.value !== '' && nowEle.value.replace(/\D/g, '') === '') {
            nowEle.value = nowEle.value.replace(/\D/g, '');
            return;
        }
        setOpeationIndex(index);
        const preEle: any = index ? document.getElementById(showAmount[index - 1].ref as string) : '';
        const nextEle: any = index !== 3 ? document.getElementById(showAmount[index + 1].ref as string) : '';
        codeArrNew[index] = nowEle.value;
        setCodeArrNew([...codeArrNew]);
        props.setCodeArr([...codeArrNew]);
        // 删除
        if ($event.keyCode === 8) {
            codeArrNew[index] = '';
            setCodeArrNew([...codeArrNew]);
            props.setCodeArr([...codeArrNew]);
            if (preEle) {
                preEle.focus();
            }
        } else {
            if (index === 3) {
                nowEle.blur();
            }
            nextEle && nextEle.focus();
        }
    };
    useEffect(() => {
        if (props.modalStep === 1) {
            document.getElementById('codeOne')?.focus();
        }
    }, [props.modalStep]);

    return (
        <div className="numInputs">
            {showAmount.map((n, index) => {
                return (
                    <input
                        maxLength={1}
                        onKeyUp={e => nextFocus(e, index)}
                        id={n.ref}
                        key={index}
                        type="tel"
                        autoComplete="off"
                        // disabled={!!codeArrNew[index] && index !== 3 && opeationIndex >= index + 2}
                    />
                );
            })}
        </div>
    );
};

export default CodeInput;
