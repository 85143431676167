import axios from '../utils/index';
import { Pat } from '@/typings/request';
const settingsApi = {
    getUserProfileInfo(callback: (T: any) => void) {
        axios
            .post(`/portal/user/info`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    editUser(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/portal/user/updateUserInfo`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getUserPointList(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/point-record-entity/list?pageNum=${params.pageNum}&pageSize=${params.pageSize}`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getUserAwardList(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/user-award-entity/list?pageNum=${params.pageNum}&pageSize=${params.pageSize}`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getUserlLotteryList(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/user-lottery-entity/list?pageNum=${params.pageNum}&pageSize=${params.pageSize}`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
};
export default settingsApi;
