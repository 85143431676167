import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import api from '@/api/login';
import './index.scss';
import logoIcon from '@/assets/img/logo@2x.png';
import CodeInput from '@/components/code-input';
import TopNav from '@/components/top-nav/index';
import { useCountDown } from 'ahooks';
import { useSelector } from 'react-redux';
import { setUserInfo } from '@/store/stateSlice';
import { useAppDispatch } from '@/store/hooks';
const VerifyCode = (props: any) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { userInfo } = useSelector((state: any) => state.state);
    const dispatch = useAppDispatch();
    const searchParams = new URLSearchParams(location.search);
    const type = searchParams.get('type') ?? '1';
    const [loading, setLoading] = useState(false);
    const [loadingRe, setLoadingRe] = useState(false);
    const [targetDate, setTargetDate] = useState<number>();
    const [countdown] = useCountDown({
        targetDate,
        onEnd: () => {
            setTargetDate(0);
        },
    });
    const [codeArr, setCodeArr] = useState(['', '', '', '']);
    const resendClick = () => {
        if (targetDate || loadingRe) return;
        setLoadingRe(true);
        const pat = {
            areaCode: '+61',
            type: Number(type) === 2 ? 0 : 1,
            userMobile: userInfo.phone.split(' ').join(''),
        };
        api.sendSmsCode(pat, res => {
            const { code, data } = res;
            if (code === 200) {
                setTargetDate(Date.now() + 60000);
                window.utils.alert('success', 'Sent successfully');
            } else {
                window.utils.alert('error', res.message);
            }
            setLoadingRe(false);
        });
    };
    const nextStep = () => {
        setLoading(true);
        const pat = {
            areaCode: '+61',
            code: codeArr.join(''),
            type: Number(type) === 2 ? 0 : 1, // 0 注册 1 重置密码
            userMobile: userInfo.phone.split(' ').join(''),
        };
        api.checkSmsCode(pat, res => {
            const { data, code, message } = res;
            setLoading(false);
            if (code === 200) {
                if (Number(type) === 1) {
                    const obj = Object.assign({ ...userInfo }, { code: codeArr.join('') });
                    dispatch(setUserInfo(obj));
                }
                navigate(`${Number(type) === 1 ? '/setPassword' : '/completeInformation'}`);
            } else {
                window.format.alert('error', message, code);
            }
        });
    };
    return (
        <div className="login-main">
            <TopNav type={Number(type)}></TopNav>
            <div className="r-left login-box">
                <img src={logoIcon} className="logo-box" alt="" />
                <div className="login-form">
                    <h1>SMS verification code</h1>
                    <div>
                        <p className="form-tip">Enter code received by SMS</p>
                        <CodeInput modalStep={1} setCodeArr={setCodeArr}></CodeInput>
                    </div>
                    <Button
                        className="r-primary_btn mart40 mart184"
                        disabled={codeArr.findIndex(v => !v) !== -1}
                        onClick={() => nextStep()}
                        loading={loading}
                    >
                        Continue
                    </Button>
                    <div
                        className={`r-defaultBlickNew_btn mart20 r-center ${countdown !== 0 ? 'disabled' : ''}`}
                        onClick={() => resendClick()}
                    >
                        {countdown === 0 ? 'Resend' : `Resend (${Math.round(countdown / 1000)}s)`}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VerifyCode;
