import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, FreeMode } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import './index.scss';
import '@/style/swiper.scss';
import { useNavigate } from 'react-router-dom';
import memberData from '@/utils/member';
const MemberCard = props => {
    const { deviceType, profileInfo } = useSelector((state: any) => state.state);
    const navigate = useNavigate();
    const memberOptionList = memberData.memberOptionList;
    const skipMember = () => {
        // free member
        navigate('/paySuccess?type=3');
    };
    const updateMember = (i: number) => {
        navigate(
            `/memberOption?type=${props.type}&subscriptionId=${memberData.subscriptionList[i].subscriptionId}&subscriptionPackageId=${profileInfo.subscriptionPackageId}`
        );
    };
    return (
        <div className="membercard-box">
            {deviceType === 'pc' ? (
                <>
                    <div className="memberOption-box r-left">
                        {memberOptionList.map((item, i) => {
                            return (
                                <div className={`member-card ${props.className}`} key={`pc-memberCard-${i}`}>
                                    {i === 1 ? <div className="mostBox">MOST POPULAR</div> : ''}
                                    <div className="mem-tit">{item.tit}</div>
                                    {item.priceList.map((val, k) => {
                                        return (
                                            <div
                                                key={`pc-memCard-${i}`}
                                                className={`price-card ${k === 1 ? 'mar12' : ''}`}
                                            >
                                                <div className="price-date">{val.date}</div>
                                                <div className="r-left">
                                                    <div className="price-box r-left">
                                                        <span className="priceLef">$ </span>
                                                        <span className="priceNum">{val.price}</span>
                                                    </div>
                                                    <div className="price-pts">{val.pts}</div>
                                                </div>
                                                <div className="price-tip">{val.tip}</div>
                                                {val.dicount ? <div className="discountBox">{val.dicount}</div> : ''}
                                            </div>
                                        );
                                    })}
                                    <div className="sign-btn r-center" onClick={() => updateMember(i)}>
                                        Sign Up
                                    </div>
                                    {props.isShowSkip ? (
                                        <div className="skip-btn r-center" onClick={() => skipMember()}>
                                            Skip
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                    <div
                        className={`memberOption-box r-left free-member ${props.className}`}
                        style={{ display: props.isShowFree ? 'flex' : 'none' }}
                    >
                        <div className="member-card">
                            <div className="mem-tit">Freemium</div>
                            <div className={`price-card`}>
                                <div className="price-date">28 DAYS</div>
                                <div className="r-left">
                                    <div className="price-box r-left">
                                        <span className="priceLef">$ </span>
                                        <span className="priceNum">0.00</span>
                                    </div>
                                    <div className="price-pts">15,000 PTS</div>
                                </div>
                                <div className="price-tip">
                                    <p className="marb20">Sign up for 28 days free</p>
                                    <p className="marb20">Compete surveys & accumulate rewards points</p>
                                    <p className="marb20">Watch your balance grow</p>
                                    <p>After 28 days choose your membership & receive 15,000 sign up bonus points.</p>
                                </div>
                            </div>
                            <div className="sign-btn r-center free-active">Activated</div>
                            <div className="skip-btn r-center" onClick={() => skipMember()}>
                                Skip
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <Swiper
                    slidesPerView="auto"
                    spaceBetween={16}
                    pagination={{
                        clickable: true,
                    }}
                    freeMode={false}
                    modules={[Pagination]}
                    className={`memberOption-box introduction-card ${props.className}`}
                >
                    {props.isShowFree ? (
                        <SwiperSlide>
                            <div className="member-card free-member">
                                <div className="mem-tit">Freemium</div>
                                <div className={`price-card`}>
                                    <div className="price-date">28 DAYS</div>
                                    <div className="r-left">
                                        <div className="price-box r-left">
                                            <span className="priceLef">$ </span>
                                            <span className="priceNum">0.00</span>
                                        </div>
                                        <div className="price-pts">15,000 PTS</div>
                                    </div>
                                    <div className="price-tip">
                                        <p className="marb20">Sign up for 28 days free</p>
                                        <p className="marb20">Compete surveys & accumulate rewards points</p>
                                        <p className="marb20">Watch your balance grow</p>
                                        <p>
                                            After 28 days choose your membership & receive 15,000 sign up bonus points.
                                        </p>
                                    </div>
                                </div>
                                <div className="sign-btn r-center free-active">Activated</div>
                                <div className="skip-btn r-center" onClick={() => skipMember()}>
                                    Skip
                                </div>
                            </div>
                        </SwiperSlide>
                    ) : (
                        <></>
                    )}
                    {memberOptionList.map((item, i) => {
                        return (
                            <SwiperSlide key={`mb-swiperCard-${i}`}>
                                <div className={`member-card ${i === 1 ? 'other-color' : ''}`}>
                                    {i === 1 ? <div className="mostBox">MOST POPULAR</div> : ''}
                                    <div className="mem-tit">{item.tit}</div>
                                    {item.priceList.map((val, k) => {
                                        return (
                                            <div
                                                key={`mb-swiperPrice-${k}`}
                                                className={`price-card ${k === 1 ? 'mar12' : ''}`}
                                            >
                                                <div className="price-date">{val.date}</div>
                                                <div className="r-left">
                                                    <div className="price-box r-left">
                                                        <span className="priceLef">$ </span>
                                                        <span className="priceNum">{val.price}</span>
                                                    </div>
                                                    <div className="price-pts">{val.pts}</div>
                                                </div>
                                                <div className="price-tip">{val.tip}</div>
                                                {val.dicount ? <div className="discountBox">{val.dicount}</div> : ''}
                                            </div>
                                        );
                                    })}
                                    <div className="sign-btn r-center" onClick={() => updateMember(i)}>
                                        Sign Up
                                    </div>
                                    {props.isShowSkip ? (
                                        <div className="skip-btn r-center" onClick={() => skipMember()}>
                                            Skip
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            )}
        </div>
    );
};
export default MemberCard;
