import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '@/api/login';
import './index.scss';
import logoIcon from '@/assets/img/logo@2x.png';
import MembershipOptionCard from '@/components/membership-option-card';
interface loginData {
    email: string;
    password: string;
}
const IntroductionPage = (props: any) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const type = searchParams.get('type') ?? '1'; // 1 引导页 2 升级会员选择套餐页
    const navigate = useNavigate();
    const nextStep = () => {
        navigate('/verifyCode');
    };
    return (
        <div className="IntroductionPage-main">
            <div className="r-center">
                <img src={logoIcon} className="logo-box" alt="" />
            </div>
            <div className="menbership-box">
                <h1>Membership Option</h1>
                <MembershipOptionCard type={Number(type)} isShowSkip={false} isShowFree={false}></MembershipOptionCard>
            </div>
        </div>
    );
};

export default IntroductionPage;
