import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import api from '@/api/home';
import './style/SurveyRespond.scss';
import logoIcon from '@/assets/img/logo@2x.png';
import failImg from '@/assets/img/fail-img.png';
import { useSelector } from 'react-redux';
import useLoading from '@/hook/useLoading';
import { defaultInfo } from '@/typings/allType';
const SurveyRespond = (props: any) => {
    const { profileInfo } = useSelector((state: any) => state.state);
    const navigate = useNavigate();
    const { mainLoading, setLoadingStatus } = useLoading();
    const [isHaveQuestion, setIsHaveQuestion] = useState(false);
    const [isLase, setIsLast] = useState(false);
    const [pointLimit, setPointLimit] = useState(false);
    const [answerLimit, setAnswerLimit] = useState(false);
    const [point, setPoint] = useState(0);
    const [questionIndex, setQuestionIndex] = useState(1);
    const [questionInfo, setQuestionInfo] = useState({
        questionId: '',
        categoryName: '',
        icon: '',
        question: '',
        answerVoList: [] as defaultInfo[],
    });
    const nextQuestion = () => {
        if (isLase) {
            window.format.alert('error', " That's the last question.");
            return;
        }
        window.sessionStorage.setItem('questionIndex', (questionIndex + 1).toString());
        window.scrollTo(0, 0);
        setQuestionIndex(questionIndex + 1);
        getQuestion();
    };
    const chooseAnswer = (key: number) => {
        if (questionInfo.answerVoList.findIndex(v => v.isSelect) !== -1) return;
        setLoadingStatus(true);
        api.answerQuestion({ answerId: questionInfo.answerVoList[key].answerId }, res => {
            const { data, code, message } = res;
            setLoadingStatus(false);
            if (code === 200) {
                questionInfo.answerVoList[key].isSelect = true;
                setQuestionInfo({ ...questionInfo });
                data.totalPoint = window.utils.formatPonit(data.totalPoint ?? 0);
                setPoint(data.totalPoint);
                setIsLast(data.isLast);
                if (data.pointLimit) {
                    window.format.alert('error', `The maximum number of points earned today has been reached.`);
                    return;
                }
                window.format.alert('success', `You have earned ${data.point} ${data.point > 1 ? 'points' : 'point'}.`);
            } else {
                window.format.alert('error', message, code);
            }
        });
    };
    const getQuestion = () => {
        setLoadingStatus(true);
        api.getRandomQuestion(res => {
            const { data, code, message } = res;
            setLoadingStatus(false);
            if (code === 200) {
                if (data && data !== null) {
                    data.point = window.utils.formatPonit(data.point ?? 0);
                    setPoint(data.point);
                    if (data.answerLimit) {
                        setAnswerLimit(true);
                        return;
                    }
                    if (data.pointLimit) {
                        setPointLimit(true);
                        // return;
                    }
                    if (data.questionId === null) {
                        setIsLast(true);
                        setIsHaveQuestion(false);
                        return;
                    }
                    // if (data.answerLimit) {
                    //     window.format.alert('error', 'The maximum number of points earned today has been reached.');
                    //     return;
                    // }
                    if (data.answerVoList && data.answerVoList !== null) {
                        data.answerVoList = data.answerVoList.map(item => {
                            return Object.assign(item, {
                                value: item.answer,
                                isSelect: false,
                            });
                        });
                    }
                    setIsHaveQuestion(true);
                    setQuestionInfo({ ...questionInfo, ...data });
                }
            } else {
                setIsHaveQuestion(false);
                window.format.alert('error', message, code);
            }
        });
    };
    useEffect(() => {
        if (profileInfo.isExpired) {
            navigate('/introductionPage?type=2');
            return;
        }
        getQuestion();
        const questionIndex = Number(window.sessionStorage.getItem('questionIndex') ?? '1');
        setQuestionIndex(questionIndex);
    }, []);
    return (
        <div className="SurveyRespond-main r-center">
            <img src={logoIcon} className="logo-box" alt="" />
            <div className="profile-rightBox">
                <div className="r-left profile-numBox">
                    <div className="statistics-card gift-card">
                        Your rewards balance
                        <div className="sta-num gift-num">{point ?? 0}</div>
                    </div>
                </div>
                {answerLimit ? (
                    <div className="noMessage-box r-center" style={{ textAlign: 'center' }}>
                        The number of survey questions that can be answered today is 0.
                    </div>
                ) : // : pointLimit ? (
                //     <div className="noMessage-box r-center" style={{ textAlign: 'center' }}>
                //         The maximum number of points earned today has been reached.
                //     </div>
                // )
                isHaveQuestion ? (
                    <>
                        <div className="question-num">Survey Question {questionIndex ?? 1}</div>
                        <p className="cate_tip">Category</p>
                        <div className={`r-left category-boxM`}>
                            <img
                                src={questionInfo.icon ? questionInfo.icon : failImg}
                                className="category-icon"
                                onError={(e: any) => (e.target.src = failImg)}
                                alt=""
                            />
                            {questionInfo.categoryName ?? '-'}
                        </div>
                        <p className="cate_tip marb8">Question</p>
                        <div className="question-box">
                            <p className="question-tit">{questionInfo.question}</p>
                            {questionInfo.answerVoList.map((item, i) => {
                                return (
                                    <div
                                        className={`question-answer r-center ${item.isSelect ? 'selected' : ''}`}
                                        key={i}
                                        onClick={() => chooseAnswer(i)}
                                    >
                                        {item.value}
                                    </div>
                                );
                            })}
                        </div>
                    </>
                ) : (
                    <div className="noMessage-box r-center">No survey question</div>
                )}
                <div className="r-left question-opeationBox">
                    <Button
                        className={`r-gray_btn w220 ${
                            questionInfo.answerVoList.findIndex(v => v.isSelect) !== -1 ? '' : 'disabled'
                        }`}
                        onClick={() => navigate('/home')}
                    >
                        Finish For Now
                    </Button>
                    <Button className="r-primary_btn w220" disabled={!isHaveQuestion} onClick={() => nextQuestion()}>
                        Next Survey
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default SurveyRespond;
