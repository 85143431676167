const subscriptionList = [
    {
        updataTip: 'Available prizes in the Bronze membership',
        icon: require('../assets/img/home/copper.png'),
        tit: 'Bronze',
        price: '$20,000',
        tip: 'Bronze Membership Holders Can Only Enter Bronze Prize Draw',
        prizeTip: 'Bronze - $20,000 Maximum',
        isMax: true,
        isMost: false,
        subscriptionId: 1,
        subscriptionPackageIds: [1, 2, 3],
        infoList: [
            'Concert packages(BOXES)',
            'Footyshow Packages(SYD/MELB-Boxes)',
            'Shopping Vouchers for entire home of furnishings(Havey norman & others)',
            'Groceries Paid for 1 years ($20,000)',
            'Fuel Paid for 1 years ($10,000)',
            'Club memberships - 5 yeras(AFL/NRL/FFA)',
            'Yearly utilities package(water, elec, gas, rates) (maximum $5000)',
            'insurance packages(health, motor, vihicles, life - maximum $7500)',
        ],
    },
    {
        updataTip: 'Available prizes in the Silver membership',
        icon: require('../assets/img/home/silver.png'),
        tit: 'Silver',
        price: '$50,000+',
        tip: 'Silver Membership Holders Can Only Enter Bronze And Silver Prize Draws',
        prizeTip: 'Silver - $50,000+ Maximum',
        isMax: false,
        isMost: true,
        subscriptionId: 2,
        subscriptionPackageIds: [4, 5, 6],
        infoList: [
            "Holiday Packages (Accom', Airfare, Tours)",
            'M.C.G. Boxes (Cricket, Footy, Soccer)',
            'C.C.A Boxes',
            'S.C.G. Boxes',
            'House Boat Holidays - Hawkesbury / Murray',
            'University Fees Paid (Aust Wide)',
            'Rent or Mortgage Paid For 1 Year (Maximum $25,000)',
            'Helicopter Flights',
            'Your deposit on your own home $50,000 (made payable to a particular contract).',
        ],
    },
    {
        updataTip: 'Available prizes in the Gold membership',
        icon: require('../assets/img/home/gold.png'),
        tit: 'Gold',
        price: '$120,000+',
        tip: 'Gold Membership Holders Can Enter All Prize Draws',
        prizeTip: 'Gold - $120,000+ Maximum',
        isMax: false,
        isMost: false,
        subscriptionId: 3,
        subscriptionPackageIds: [7, 8, 9],
        infoList: [
            'House & Land Packages $500,000',
            'House - $300,000',
            'Land - $200,000',
            'Investment Portofolio Y.B.R. - $250K',
            'Car (4WD) & Caravan Packages (INCL’ INS’) - $120,000',
            '1st Class Airfare Upgrade on all Flights Taken',
            'Rent or Mortgage Paid For 1 Year (Maximum $25,000)',
            'Helicopter Flights',
            'Your deposit on your own home $50,000 (made payable to a particular contract).',
        ],
    },
];

const memberOptionList = [
    {
        tit: 'Bronze',
        subscriptionId: 1,
        priceList: [
            {
                date: '1 YEAR',
                price: '25.00',
                pts: '20,000 PTS',
                dicount: '',
                tip: 'When you sign up, you receive these promotional starter points',
                ptsNum: 20000,
                subscriptionPackageId: 1,
            },
            {
                date: '2 YEAR',
                price: '45.00',
                pts: '35,000 PTS',
                dicount: 'A saving of $5.00',
                tip: 'When you sign up for 2 years, you receive these promotional starter points',
                ptsNum: 35000,
                subscriptionPackageId: 2,
            },
            {
                date: '3 YEAR',
                price: '65.00',
                pts: '50,000 PTS',
                dicount: 'A saving of $10.00',
                tip: 'When you sign up for 3 years, you receive these promotional starter points',
                ptsNum: 50000,
                subscriptionPackageId: 3,
            },
        ],
    },
    {
        tit: 'Silver',
        subscriptionId: 2,
        priceList: [
            {
                date: '1 YEAR',
                price: '50.00',
                pts: '30,000 PTS',
                dicount: '',
                tip: 'When you sign up, you receive these promotional starter points',
                ptsNum: 30000,
                subscriptionPackageId: 4,
            },
            {
                date: '2 YEAR',
                price: '85.00',
                pts: '50,000 PTS',
                dicount: 'A saving of $15.00',
                tip: 'When you sign up for 2 years, you receive these promotional starter points',
                ptsNum: 50000,
                subscriptionPackageId: 5,
            },
            {
                date: '3 YEAR',
                price: '120.00',
                pts: '75,000 PTS',
                dicount: 'A saving of $30.00',
                tip: 'When you sign up for 3 years, you receive these promotional starter points',
                ptsNum: 75000,
                subscriptionPackageId: 6,
            },
        ],
    },
    {
        tit: 'Gold',
        subscriptionId: 3,
        priceList: [
            {
                date: '1 YEAR',
                price: '75.00',
                pts: '50,000 PTS',
                dicount: '',
                tip: 'When you sign up, you receive these promotional starter points',
                ptsNum: 20000,
                subscriptionPackageId: 7,
            },
            {
                date: '2 YEAR',
                price: '120.00',
                pts: '75,000 PTS',
                dicount: 'A saving of $30.00',
                tip: 'When you sign up for 2 years, you receive these promotional starter points',
                ptsNum: 75000,
                subscriptionPackageId: 8,
            },
            {
                date: '3 YEAR',
                price: '180.00',
                pts: '100,000 PTS',
                dicount: 'A saving of $45.00',
                tip: 'When you sign up for 3 years, you receive these promotional starter points',
                ptsNum: 100000,
                subscriptionPackageId: 9,
            },
        ],
    },
];

export default {
    subscriptionList,
    memberOptionList,
};
