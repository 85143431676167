import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Divider, Spin } from 'antd';
import api from '@/api/profile';
import './index.scss';
import logoIcon from '@/assets/img/logo@2x.png';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import useLoading from '@/hook/useLoading';
const PrizeRecord = (props: any) => {
    const { deviceType, profileInfo } = useSelector((state: any) => state.state);
    const navigate = useNavigate();
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [maxPages, setMaxPages] = useState(1);
    const [loading, setLoading] = useState(false);
    const [pointsRecordList, setPointsRecordList] = useState<any>([]);
    const getList = (pageNow?: number) => {
        const pat = { pageNum: pageNow ? pageNow : page, pageSize: 20, userId: profileInfo.userId };
        api.getUserAwardList(pat, res => {
            const { data, code, message } = res;
            setLoading(false);
            if (code === 200) {
                data.records.forEach(item => {
                    item.createTime = item.createTime ? window.utils.foramtTimeDMYHM_easy(+item.createTime) : '-';
                    item.drawDate = item.drawDate ? window.utils.foramtTimeDMYHM_easy(+item.drawDate) : '-';
                    item.titleShow = item.drawPoints ? window.utils.formatPonit(item.drawPoints) : 0;
                });
                setTotal(data.total);
                setMaxPages(data.pages);
                setPointsRecordList([...pointsRecordList, ...data.records]);
            } else {
                window.format.alert('error', message, code);
            }
        });
    };
    const loadMoreData = () => {
        if (loading) {
            return;
        }
        setLoading(true);
        getList(page + 1);
        setPage(page + 1);
    };
    useEffect(() => {
        setLoading(true);
        getList(1);
    }, []);
    return (
        <div className="membership-main pointrecord-main">
            <div className="zIndexTop">
                <div className="r-center">
                    <img src={logoIcon} className="logo-box" alt="" />
                </div>
                <h1 className="pointH1">Prize record</h1>
                <section className="record-main prize-main_new r-left">
                    <InfiniteScroll
                        dataLength={total}
                        next={loadMoreData}
                        hasMore={pointsRecordList.length < total}
                        loader={
                            <Divider plain style={{ color: '#fff' }}>
                                <Spin
                                    delay={500}
                                    indicator={<div className="loading_icon" style={{ fontSize: 30 }}></div>}
                                    size="large"
                                ></Spin>
                            </Divider>
                        }
                        // endMessage={
                        //     <Divider plain style={{ color: '#fff' }}>
                        //         It is all, nothing more
                        //     </Divider>
                        // }
                        scrollableTarget="scrollableDiv"
                    >
                        {pointsRecordList.length ? (
                            <div className="r-left recordPrize_listBox draw-box" style={{ alignItems: 'flex-start' }}>
                                {pointsRecordList.map((val, i) => {
                                    return (
                                        <div className={`record-card prize-card`} key={`prizeWin-${i}`}>
                                            <div className="r-left quesInfo">{val.prizeName ?? '-'}</div>
                                            <p className="get-time prizeGet_time pointNum">
                                                {val.drawPoints ?? 0} {val.drawPoints > 1 ? 'points' : 'point'}
                                            </p>
                                            <p className="get-time prizeGet_time">Draw time：{val.createTime ?? '-'}</p>
                                            <p className="get-time prizeGet_time">
                                                Winning time：{val.drawDate ?? '-'}
                                            </p>
                                        </div>
                                    );
                                })}
                            </div>
                        ) : (
                            <div className="r-center noMessage-box centerEmptyText">
                                {loading ? (
                                    <Divider plain style={{ color: '#fff' }}>
                                        <Spin
                                            delay={500}
                                            indicator={<div className="loading_icon" style={{ fontSize: 30 }}></div>}
                                            size="large"
                                        ></Spin>
                                    </Divider>
                                ) : (
                                    'No data'
                                )}
                            </div>
                        )}
                    </InfiniteScroll>
                </section>
            </div>
        </div>
    );
};

export default PrizeRecord;
