import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '@/api/category';
import './index.scss';
import logoIcon from '@/assets/img/logo@2x.png';
import failImg from '@/assets/img/fail-img.png';
import { useSelector } from 'react-redux';
import { defaultInfo } from '@/typings/allType';
import useLoading from '@/hook/useLoading';
const Category = (props: any) => {
    const { deviceType } = useSelector((state: any) => state.state);
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [loading, setLoading] = useState(false);
    const { mainLoading, setLoadingStatus } = useLoading();
    // 1 cate 2 Introduction-page
    const type = searchParams.get('type') ?? '1';
    const nextStep = (key?: number) => {
        navigate('/home');
    };
    const selectCate = (key: number) => {
        if (loading) return;
        categoriesList[key].isSelect = !categoriesList[key].isSelect;
        const chooseList = categoriesList.filter(item => item.isSelect);
        setLoading(true);
        setCategoriesList([...categoriesList]);
        api.selectUserCategory({ questionCategoryId: chooseList.map(val => val.questionCategoryId) }, res => {
            const { data, code, message } = res;
            if (code === 200) {
                setLoading(false);
            } else {
                window.format.alert('error', message, code);
            }
        });
    };
    const [categoriesList, setCategoriesList] = useState<defaultInfo[]>([]);
    const getList = () => {
        setLoadingStatus(true);
        api.getCategiryList(res => {
            const { data, code, message } = res;
            setLoadingStatus(false);
            if (code === 200) {
                const list = data.map(item => {
                    return Object.assign(item, {
                        isSelect: item.selected,
                        categoryName: item.categoryName ? item.categoryName.slice(0, 30) : '',
                    });
                });
                setCategoriesList([...list]);
            } else {
                window.format.alert('error', message, code);
            }
        });
    };
    useEffect(() => {
        getList();
    }, []);
    return (
        <>
            {!mainLoading ? (
                <div className="Category-main">
                    <div className="r-center">
                        <img src={logoIcon} className="logo-box" alt="" />
                    </div>
                    <div className="cateContent-box">
                        <div className="content-top">
                            <div className="content-tit">
                                <span>Finally, let us know</span>
                                <span className="content-col">
                                    where you want {deviceType === 'mb' ? <br /> : ''} your opinion heard
                                </span>
                            </div>
                            <p className="top-tip">Select the categories you would like to Give Your Opinion for!</p>
                        </div>
                        {categoriesList.length ? (
                            <div className={`r-left category-listBox ${Number(type) === 2 ? 'cate_show_box' : ''}`}>
                                {categoriesList.map((item, i) => {
                                    return (
                                        <div
                                            className={`r-left category-box ${item.isSelect ? 'selected' : ''}`}
                                            key={i}
                                            onClick={() => {
                                                selectCate(i);
                                            }}
                                        >
                                            <img
                                                src={item.icon ? item.icon : failImg}
                                                onError={(e: any) => (e.target.src = failImg)}
                                                className="category-icon"
                                                alt=""
                                            />
                                            {item.categoryName}
                                        </div>
                                    );
                                })}
                            </div>
                        ) : (
                            <div className="r-center emptyTetx">The administrator does not set a category</div>
                        )}
                        {Number(type) === 2 ? (
                            <div className="r-left category-listBox fixed-btm" style={{ marginTop: 0 }}>
                                <div className="r-center skip-btn" onClick={() => nextStep(1)}>
                                    Skip
                                </div>
                                <div
                                    className={`r-center finish-btn ${
                                        categoriesList.findIndex(v => v.isSelect) === -1 ? 'disabled' : ''
                                    }`}
                                    onClick={() => nextStep(2)}
                                >
                                    Finish
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            ) : (
                ''
            )}
        </>
    );
};

export default Category;
