import ReactDOM from 'react-dom/client';
import App from './App';
import 'babel-polyfill';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// import '@/assets/fonts/font.scss';
import 'antd/dist/reset.css';
import '@/style/antd-base.scss';
import '@/style/vant-base.scss';
import '@/style/var.scss';
import '@/style/default.scss';
import { ConfigProvider } from 'antd';
import store from '@/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import Utils from '@/utils/utils';
import { AliveScope } from 'react-activation';
(window as any).utils = Utils;
(window as any).format = Utils;
const persistor = persistStore(store);
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

// if (process.env.REACT_APP_NODE_ENV !== 'production') {
//     const script = document.createElement('script');
//     script.src = 'http://192.168.50.219:6752/page-spy/index.min.js';
//     script.crossOrigin = 'anonymous';
//     document.body.appendChild(script);
//     script.onload = () => {
//         (window as any).$pageSpy = new (window as any).PageSpy({ autoRender: false });
//     };
// }
root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ConfigProvider theme={{ token: { colorPrimary: '#FF7957' } }}>
                <AliveScope>
                    <App />
                </AliveScope>
            </ConfigProvider>
        </PersistGate>
    </Provider>
);
