import axios from '../utils/index';
import { Pat } from '@/typings/request';
const categoryApi = {
    getUserHomeInfo(callback: (T: any) => void) {
        axios
            .get(`/portal/user/home`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    selectUserCategory(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/user-question-category-entity/select`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getRandomQuestion(callback: (T: any) => void) {
        axios
            .get(`/question-entity/select/random/question`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    answerQuestion(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/user-question-record-entity/answer/question/${data.answerId}`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getPrizeList(callback: (T: any) => void) {
        axios
            .get(`/prize-entity/list`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    userLottery(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/user-lottery-entity/lottery`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getStripePayKey(callback: (T: any) => void) {
        axios
            .get(`/member-payment-records-entity/key`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getMemberPaymentInfo(data: Pat, callback: (T: any) => void) {
        axios
            .get(`/member-payment-records-entity/info/${data.subscriptionPackageId}`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    paymentWithCard(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/member-payment-records-entity/creditCardCharges`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    paymentWithOtherPay(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/member-payment-records-entity/thirdPartyPayment`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
};
export default categoryApi;
