import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, DatePicker, Input, Select } from 'antd';
import './index.scss';
import logoIcon from '@/assets/img/logo@2x.png';
import { useAppDispatch } from '@/store/hooks';
import { setUserInfo } from '@/store/stateSlice';
import TopNav from '@/components/top-nav/index';
import { useSelector } from 'react-redux';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
const disabledDate: RangePickerProps['disabledDate'] = current => {
    return current && current > dayjs().endOf('day');
};
const CompleteInformation = (props: any) => {
    const navigate = useNavigate();
    const { userInfo } = useSelector((state: any) => state.state);
    const dispatch = useAppDispatch();
    const [state, setState] = useState({
        gender: '',
        age: '',
        postcode: '',
    });
    const nextStep = () => {
        if (!state.gender) {
            window.utils.alert('error', 'Please select your gender');
            return;
        }
        if (!state.age) {
            window.utils.alert('error', 'Please select your month/year of birth');
            return;
        }
        if (!state.postcode) {
            window.utils.alert('error', 'Please enter your postcode');
            return;
        }
        const obj = Object.assign({ ...userInfo }, { ...state });
        dispatch(setUserInfo(obj));
        navigate(`/setPassword?type=2`);
    };
    const handleChange = val => {
        setState({ ...state, gender: val });
    };
    const changeAge = val => {
        const age = val ? window.utils.foramtAgeTime(val) : '';
        setState({ ...state, age });
    };
    return (
        <div className="login-main">
            <TopNav type={2}></TopNav>
            <div className="r-left login-box">
                <img src={logoIcon} className="logo-box" alt="" />
                <div className="login-form">
                    <h1>Complete information</h1>
                    <div className="baseSize">
                        <p className="form-tip">Gender</p>
                        <Select
                            className="r-select"
                            style={{ width: '100%' }}
                            onChange={handleChange}
                            placeholder="Select"
                            suffixIcon={<i className="sel_arrow_icon"></i>}
                            options={[
                                { value: '1', label: 'Female' },
                                { value: '2', label: 'Male' },
                            ]}
                        />
                    </div>
                    <div className="baseSize">
                        <p className="form-tip">Age</p>
                        <div className="ageSel-input">
                            <Input
                                className="r-input"
                                readOnly
                                value={state.age}
                                placeholder="Month/Year of Birth"
                                suffix={<i className="sel_arrow_icon"></i>}
                            />
                            <DatePicker
                                disabledDate={disabledDate}
                                className="r-age-date"
                                inputReadOnly
                                onChange={changeAge}
                                defaultValue={dayjs(new Date('2000-01'))}
                                picker="month"
                            />
                        </div>
                    </div>
                    <div className="baseSize">
                        <p className="form-tip">Postcode</p>
                        <Input
                            className="r-input"
                            maxLength={4}
                            value={state.postcode}
                            onInput={(e: any) => {
                                e.target.value = e.target.value.replace(/\D/g, '');
                                setState({ ...state, postcode: e.target.value });
                            }}
                            placeholder="Enter your postcode"
                        />
                    </div>
                    <Button
                        className="r-primary_btn mart40 mart24"
                        disabled={!state.age && !state.gender && !state.postcode}
                        onClick={() => nextStep()}
                    >
                        Continue
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default CompleteInformation;
